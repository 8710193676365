<template>
	<div>
		<van-loading/>
	</div>
</template>

<script>
	import { Loading, Toast, Dialog } from "vant"
	import { wxPayHK, isPay } from "@/js/axios"
	
	export default{
		name: 'wxPayHK',
		data (){
			return {
				package: null,
				code: '',
				id: '',
			}
		},
		created (){
			this.package = JSON.parse(sessionStorage.getItem('package'));
			this.id = sessionStorage.getItem('orderid') ? sessionStorage.getItem('orderid') : ''
			// if(this.package == null){
			// 	Toast.fail('請先選擇套餐');
			// 	this.$router.push('/home/buyCombo');
			// }
			
			//通過流程會有code參數再url
			this.code = this.$route.query.code;
			
			// Dialog({message: this.code})
			wxPayHK({//獲取openID
				code: this.code,
				disc: sessionStorage.getItem('discountCode') ? sessionStorage.getItem('discountCode') : '',
				qid: this.id,
				te: 1,
			}).then(res => {
				if(res.code == 200){
					var redirectUrl = location.origin + '/' + this.$router.resolve({
						path: "/payment/1"
					}).href;
					var result = JSON.parse(atob(res.data));
					
					sessionStorage.setItem('o_sn', result.o_sn)
					
					location.href = result.url + '&redirect_url=' + encodeURIComponent(redirectUrl);
				}else{
					Toast.fail(res.msg);
					this.$router.push('/payment/0');
				}
			})
		}
	}
</script>

<style scoped>
	
</style>
