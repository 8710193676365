<template>
    <div class="content">

        <van-nav-bar
                title="修改信息"
                left-arrow
                @click-left="$router.back(-1)"
                :fixed="true"
        />
        <van-progress
                color="#6681FA"
                :percentage="progressNum"
                :show-pivot="false"
                pivot-text=""
                stroke-width="1"
                track-color="#DCDEE5"
        />
        <van-swipe :loop="false" :show-indicators="true" :touchable="false" ref="nextSwipe"
                   :initial-swipe="currentIndex" :height="swipeHeight">
            <van-swipe-item>
                <div class="swipeBox">
                    <div class="swiperScroll">
                        <div class="question">
                            <div class="avatar"></div>
                            <div class="quesText">
                                您好，請告訴我您的身高、體重和體脂率？
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="reply">
                            <div class="group">
                                <span>身高</span>
                                <div class="groupLabel">
                                    <label>
                                        <van-field v-model="height" placeholder="請填寫" type="number"/>
                                        <div class="unit"><span>cm</span></div>
                                    </label>
                                </div>
                            </div>
                            <div class="group">
                                <span>體重</span>
                                <div class="groupLabel">
                                    <label>
                                        <van-field v-model="weight" placeholder="請填寫" type="number"/>
                                        <div class="unit"><span>kg</span></div>
                                    </label>
                                </div>
                            </div>
                            <div class="group">
                                <span>體脂率</span>
                                <div class="groupLabel">
                                    <label>
                                        <van-field v-model="fat" placeholder="請填寫" type="number"/>
                                        <div class="unit"><span>%</span></div>
                                    </label>
                                </div>
                            </div>
                            <div class="group fitText">
                                若您不知道自身的體脂率信息，米因大數據會為您進行推算。
                                若您屬於健身愛好者，運動員等體脂率較為特殊的人群，請您務必填寫準確的體脂率數值。
                            </div>
                        </div>
                        <div class="nextQues" @click="nextQuest(1)">填好了</div>
                    </div>
                </div>
            </van-swipe-item>
            <van-swipe-item>
                <div class="swipeBox">
                    <div class="swiperScroll">
                        <div class="question">
                            <div class="avatar"></div>
                            <div class="quesText">
                                請告訴我您的三圍信息？
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="reply">
                           
                            <div class="group">
                                <span>腰圍</span>
                                <div class="groupLabel">
                                    <label>
                                        <van-field v-model="waist" placeholder="請填寫" type="number"/>
                                        <div class="unit"><span>cm</span></div>
                                    </label>
                                </div>
                            </div>
                            <div class="group">
                                <span>臀圍</span>
                                <div class="groupLabel">
                                    <label>
                                        <van-field v-model="hip" placeholder="請填寫" type="number"/>
                                        <div class="unit"><span>cm</span></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="nextQues" @click="nextQuest(2)">提交問卷</div>
                        <div class="prevQues" @click="prevQuest"></div>
                    </div>
                </div>
            </van-swipe-item>
<!--            <van-swipe-item>-->
<!--                <div class="swipeBox">-->
<!--                    <div class="swiperScroll">-->
<!--                        <div class="question">-->
<!--                            <div class="avatar"></div>-->
<!--                            <div class="quesText">-->
<!--                                請填寫您的血液常規檢查结果-->
<!--                            </div>-->
<!--                            <div class="clear"></div>-->
<!--                        </div>-->
<!--                        <div class="reply">-->
<!--                            <div class="group">-->
<!--                                <span>白血球(WBC)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[0].ve" placeholder="請填寫"-->
<!--                                                   @blur="wbc(examineList[0].ve)" type="number"/>-->
<!--                                        <div class="unit" style="line-height: 0.65rem">-->
<!--                                            <span>10<sup>3</sup>(10<sup>9</sup>L)</span></div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>血紅素(HGB)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[1].ve" placeholder="請填寫"-->
<!--                                                   @blur="hgb(examineList[1].ve)" type="number"/>-->
<!--                                        <div class="unit"><span>g/dL</span></div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>血小板(PLT)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[2].ve" placeholder="請填寫"-->
<!--                                                   @blur="plt(examineList[2].ve)" type="number"/>-->
<!--                                        <div class="unit" style="line-height: 0.65rem">-->
<!--                                            <span>10<sup>3</sup>(10<sup>9</sup>L)</span></div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="nextQues" @click="nextQuest(3)">填好了</div>-->
<!--                        <div class="prevQues" @click="prevQuest"></div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </van-swipe-item>-->
<!--            <van-swipe-item>-->
<!--                <div class="swipeBox ">-->
<!--                    <div class="swiperScroll">-->
<!--                        <div class="question">-->
<!--                            <div class="avatar"></div>-->
<!--                            <div class="quesText">-->
<!--                                請填寫您的血糖檢查结果-->
<!--                            </div>-->
<!--                            <div class="clear"></div>-->
<!--                        </div>-->
<!--                        <div class="reply">-->
<!--                            <div class="group">-->
<!--                                <span>空腹血糖(FG)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[3].ve" placeholder="請填寫"-->
<!--                                                   @blur="fg(examineList[3].ve)" type="number"/>-->
<!--                                        <div class="unit">-->
<!--                                            <label style="margin-right: 0.32rem;"> <input v-model="examineList[3].ut"-->
<!--                                                                                          value="B" type="radio"-->
<!--                                                                                          name="fg"-->
<!--                                                                                          @change="fgChange">-->
<!--                                                <p></p> <span>mmol/mol</span>-->
<!--                                            </label>-->
<!--                                            <label> <input v-model="examineList[3].ut" value="A"-->
<!--                                                           type="radio" name="fg"-->
<!--                                                           @change="fgChange">-->
<!--                                                <p></p> <span>mg/dL</span>-->
<!--                                            </label>-->
<!--                                        </div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>糖化血色素(HbA1c)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[4].ve" placeholder="請填寫"-->
<!--                                                   @blur="hb1ac(examineList[4].ve)" type="number"/>-->
<!--                                        <div class="unit"><span>%</span></div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="nextQues" @click="nextQuest(4)">填好了</div>-->
<!--                        <div class="prevQues" @click="prevQuest"></div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </van-swipe-item>-->
<!--            <van-swipe-item>-->
<!--                <div class="swipeBox">-->
<!--                    <div class="swiperScroll">-->
<!--                        <div class="question">-->
<!--                            <div class="avatar"></div>-->
<!--                            <div class="quesText">-->
<!--                                請填寫您的血脂肪檢查结果-->
<!--                            </div>-->
<!--                            <div class="clear"></div>-->
<!--                        </div>-->
<!--                        <div class="reply">-->
<!--                            <div class="group">-->
<!--                                <span>總膽固醇(CHOL)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[5].ve" placeholder="請填寫"-->
<!--                                                   @blur="chol(examineList[5].ve)" type="number"/>-->
<!--                                        <div class="unit">-->
<!--                                            <label style="margin-right: 0.32rem;"> <input v-model="examineList[5].ut"-->
<!--                                                                                          value="B" type="radio"-->
<!--                                                                                          name="chol"-->
<!--                                                                                          @change="cholChange">-->
<!--                                                <p></p><span>mmol/mol</span></label>-->
<!--                                            <label> <input v-model="examineList[5].ut" value="A"-->
<!--                                                           type="radio" name="chol"-->
<!--                                                           @change="cholChange">-->
<!--                                                <p></p><span>mg/dL</span></label>-->
<!--                                        </div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>低密度脂蛋白膽固醇(LDLC)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[6].ve" placeholder="請填寫"-->
<!--                                                   @blur="ldlc(examineList[6].ve)" type="number"/>-->
<!--                                        <div class="unit">-->
<!--                                            <label style="margin-right: 0.32rem;"> <input v-model="examineList[6].ut"-->
<!--                                                                                          value="B" type="radio"-->
<!--                                                                                          name="ldlc"-->
<!--                                                                                          @change="ldlcChange">-->
<!--                                                <p></p><span>mmol/mol</span></label>-->
<!--                                            <label> <input v-model="examineList[6].ut" value="A"-->
<!--                                                           type="radio" name="ldlc"-->
<!--                                                           @change="ldlcChange">-->
<!--                                                <p></p><span>mg/dL</span></label>-->
<!--                                        </div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>高密度脂蛋白膽固醇(HDLC)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[7].ve" placeholder="請填寫"-->
<!--                                                   @blur="hdlc(examineList[7].ve)" type="number"/>-->
<!--                                        <div class="unit">-->
<!--                                            <label style="margin-right: 0.32rem;"> <input v-model="examineList[7].ut"-->
<!--                                                                                          value="B" type="radio"-->
<!--                                                                                          name="hdlc"-->
<!--                                                                                          @change="hdlcChange">-->
<!--                                                <p></p><span>mmol/mol</span></label>-->
<!--                                            <label> <input v-model="examineList[7].ut" value="A"-->
<!--                                                           type="radio" name="hdlc"-->
<!--                                                           @change="hdlcChange">-->
<!--                                                <p></p><span>mg/dL</span></label>-->
<!--                                        </div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>三酸甘油脂(TG)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[8].ve" placeholder="請填寫"-->
<!--                                                   @blur="tg(examineList[8].ve)" type="number"/>-->
<!--                                        <div class="unit">-->
<!--                                            <label style="margin-right: 0.32rem;"> <input v-model="examineList[8].ut"-->
<!--                                                                                          value="B" type="radio"-->
<!--                                                                                          name="tg"-->
<!--                                                                                          @change="tgChange">-->
<!--                                                <p></p><span>mmol/mol</span></label>-->
<!--                                            <label> <input v-model="examineList[8].ut" value="A"-->
<!--                                                           type="radio" name="tg"-->
<!--                                                           @change="tgChange">-->
<!--                                                <p></p><span>mg/dL</span></label>-->
<!--                                        </div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="nextQues" @click="nextQuest(5)">填好了-->
<!--                        </div>-->
<!--                        <div class="prevQues" @click="prevQuest"></div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </van-swipe-item>-->
<!--            <van-swipe-item>-->
<!--                <div class="swipeBox">-->
<!--                    <div class="swiperScroll">-->
<!--                        <div class="question">-->
<!--                            <div class="avatar"></div>-->
<!--                            <div class="quesText">-->
<!--                                請填寫您的肝膽功能檢查结果-->
<!--                            </div>-->
<!--                            <div class="clear"></div>-->
<!--                        </div>-->
<!--                        <div class="reply">-->
<!--                            <div class="group">-->
<!--                                <label>-->

<!--                                </label>-->
<!--                                <span>肝指數GOT</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[9].ve" placeholder="請填寫"-->
<!--                                                   @blur="got(examineList[9].ve)" type="number"/>-->
<!--                                        <div class="unit"><span>IU/L(U/L)</span></div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>肝指數GPT</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[10].ve" placeholder="請填寫"-->
<!--                                                   @blur="gpt(examineList[10].ve)" type="number"/>-->
<!--                                        <div class="unit"><span>IU/L(U/L)</span></div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>堿性磷酸酶(ALP)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[11].ve" placeholder="請填寫"-->
<!--                                                   @blur="alp(examineList[11].ve)" type="number"/>-->
<!--                                        <div class="unit"><span>IU/L(U/L)</span></div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>白蛋白(ALB)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[12].ve" placeholder="請填寫"-->
<!--                                                   @blur="alb(examineList[12].ve)" type="number"/>-->
<!--                                        <div class="unit">-->
<!--                                            <label style="margin-right: 0.32rem;"> <input v-model="examineList[12].ut"-->
<!--                                                                                          value="B" type="radio"-->
<!--                                                                                          name="alb"-->
<!--                                                                                          @change="albChange">-->
<!--                                                <p></p><span>mmol/mol</span></label>-->
<!--                                            <label> <input v-model="examineList[12].ut"-->
<!--                                                           value="A"-->
<!--                                                           type="radio" name="alb"-->
<!--                                                           @change="albChange">-->
<!--                                                <p></p><span>mg/dL</span></label>-->
<!--                                        </div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="nextQues" @click="nextQuest(6)">填好了</div>-->
<!--                        <div class="prevQues" @click="prevQuest"></div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </van-swipe-item>-->
<!--            <van-swipe-item>-->
<!--                <div class="swipeBox">-->
<!--                    <div class="swiperScroll">-->
<!--                        <div class="question">-->
<!--                            <div class="avatar"></div>-->
<!--                            <div class="quesText">-->
<!--                                請填寫您的腎功能檢查结果-->
<!--                            </div>-->
<!--                            <div class="clear"></div>-->
<!--                        </div>-->
<!--                        <div class="reply">-->
<!--                            <div class="group">-->
<!--                                <span>肌酸酐(Cre)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[13].ve" placeholder="請填寫"-->
<!--                                                   @blur="cre(examineList[13].ve)" type="number"/>-->
<!--                                        <div class="unit">-->
<!--                                            <label style="margin-right: 0.32rem;"> <input v-model="examineList[13].ut"-->
<!--                                                                                          value="A"-->
<!--                                                                                          type="radio" name="cre"-->
<!--                                                                                          @change="creChange">-->
<!--                                                <p></p><span>mg/dL</span></label>-->
<!--                                            <label> <input v-model="examineList[13].ut"-->
<!--                                                           value="B" type="radio" name="cre"-->
<!--                                                           @change="creChange">-->
<!--                                                <p></p><span>μmol/L</span></label>-->
<!--                                        </div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>尿酸(UA)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[14].ve" placeholder="請填寫"-->
<!--                                                   @blur="ua(examineList[14].ve)" type="number"/>-->
<!--                                        <div class="unit">-->
<!--                                            <label style="margin-right: 0.32rem;"> <input v-model="examineList[14].ut"-->
<!--                                                                                          value="A"-->
<!--                                                                                          type="radio" name="ua"-->
<!--                                                                                          @change="uaChange">-->
<!--                                                <p></p><span>mg/dL</span></label>-->
<!--                                            <label> <input v-model="examineList[14].ut"-->
<!--                                                           value="B" type="radio" name="ua"-->
<!--                                                           @change="uaChange">-->
<!--                                                <p></p><span>μmol/L</span></label>-->
<!--                                        </div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>腎絲球過濾率(eGFR)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[15].ve" placeholder="請填寫"-->
<!--                                                   @blur="egfr(examineList[15].ve)" type="number"/>-->
<!--                                        <div class="unit"><span-->
<!--                                                style="line-height: 0.65rem">ml/min/1.73m <sup>2</sup></span></div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="nextQues" @click="nextQuest(7)">提交問卷</div>-->
<!--                        <div class="prevQues" @click="prevQuest">-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </van-swipe-item>-->
        </van-swipe>
        <div class="mark" v-show="markShowNull">
            <div>
                <div class="markBox">
                    <img src="../../assets/images/errorTip.png" alt="">
                    <div class="tipText">
                        您有尚未完成的健康大数据评估，是否继续完成？
                    </div>
                    <div class="btnGroup">
                        <div class="btnEsc" @click="backHome">返回首页</div>
                        <div class="btnEnt" @click="close">继续完成</div>
                    </div>
                </div>
                <div class="close" @click="close"></div>
            </div>
        </div>
        <div class="mark" v-show="markShowError">
            <div>
                <div class="markBoxError">
                    <div class="markBoxErrorImg">
                        <img src="../../assets/images/tips.png" alt="">
                    </div>
                    <div class="tipText2" v-html="errorTip">

                    </div>
                    <div class="btnGroup">
                        <div class="btnEnt2 qjc-fts-32" @click="close">我知道了</div>
                    </div>
                </div>
                <div class="close" @click="close"></div>
            </div>
        </div>

    </div>
</template>

<script>
    import editQuestion from '../../js/editQuestion';
    import Vue from 'vue';
    import {NavBar, Progress, Swipe, SwipeItem, RadioGroup, Radio, Checkbox, CheckboxGroup, Field, Picker, Popup, Area, DatetimePicker} from 'vant';

    Vue.use(NavBar).use(Progress).use(Swipe).use(SwipeItem).use(RadioGroup).use(Radio).use(Checkbox).use(CheckboxGroup).use(Field).use(Picker).use(Popup).use(Area).use(DatetimePicker);
    export default {
        ...editQuestion
    }
</script>

<style src="../../assets/css/editQuestion.css" scoped>

</style>
<style scoped>
    .content >>> .van-icon-arrow-left:before {
        color: #40444D;
    }

    .content >>> .van-nav-bar__title {
        font-size: 0.36rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
    }

    .content >>> .van-progress {
        margin-top: 46px;
    }

    .content >>> .van-radio {
        padding: 0.4rem 0;
        border-bottom: 0.01rem solid #EEEEEE;
    }

    .content >>> .van-radio:last-child {
        border: none;
    }

    .content >>> .van-checkbox {
        padding: 0.4rem 0;
        border-bottom: 0.01rem solid #EEEEEE;
    }

    .content >>> .van-checkbox__icon .van-icon {
        border-radius: 4px;
    }

    .content >>> .van-swipe__indicators {
        bottom: -12px;
    }

    .content >>> .van-cell {
        padding: 0;
        float: left;
        width: 100%;
        height: 0.7rem;
    }

    .content >>> .van-cell input {
        float: left;
        font-size: 0.32rem;
        height: 0.7rem;
        line-height: 0.7rem;
    }

    .content >>> .van-cell input::placeholder {
        font-size: 0.32rem;
        font-family: PingFang SC;
        font-weight: 300;
        color: rgba(161, 167, 178, 1);
    }

    .selArea >>> .van-cell {
        width: 100% !important;
    }
</style>
