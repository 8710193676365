import { render, staticRenderFns } from "./editQuestion.vue?vue&type=template&id=0e680fcd&scoped=true"
import script from "./editQuestion.vue?vue&type=script&lang=js"
export * from "./editQuestion.vue?vue&type=script&lang=js"
import style0 from "../../assets/css/editQuestion.css?vue&type=style&index=0&id=0e680fcd&prod&scoped=true&lang=css&external"
import style1 from "./editQuestion.vue?vue&type=style&index=1&id=0e680fcd&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e680fcd",
  null
  
)

export default component.exports