<template>
	<div>
		<van-nav-bar title='米因登錄' left-arrow fixed class="qjc-nav-bar" @click-left="$router.back()" />

		<div class="login qjc-input-group">
			<h2 class="title qjc-c-dark qjc-texta-l">米因健康AI服務平台</h2>
			<div class="mobile_cell">
				<div class="pick_cell" @click="picker_show = true">{{areaCode}} <van-image class="" width="0.24rem"
						height="0.24rem"
						:src="require(picker_show ? '@/assets/images/caret-up.png' : '@/assets/images/caret-down.png')"></van-image>
				</div>
				<van-field v-model="account" placeholder="請輸入手機號" type="number" :maxlength="20" />
			</div>
			<div class="mobile_cell">
				<van-field placeholder="請輸入驗證碼" v-model="code" type="number" :maxlength="6">
					
					<van-button 
						slot="button" 
						class="get-code qjc-c-primary" 
						:class="isImageCode(isSend,account,areaCode) ? 'disabled':''" 
						@click="getImgCode">
						<span v-show="isSend">
							<van-count-down class="qjc-inline" :time="2 * 60 * 1000" format="ss" ref="countDown"
								:auto-start="false" @finish="finish" />秒後重發
						</span>
						<span v-show="!isSend">{{phoneCodeText}}</span>
					</van-button>
				</van-field>

			</div>

			<van-button @click="login" plain round type="info" class="login-btn qjc-wid-100 qjc-fts-36 qjc-ftw-b">
				登 錄
			</van-button>

		</div>

		<!-- 底部 -->
		<footer class="qjc-wid-100 qjc-fixed">本產品目前僅適用於20-90周歲人群</footer>

		<div class="agreement qjc-texta-l">
			<van-checkbox v-model="isAgree" icon-size="0.36rem">
				我已閲讀並同意 <span @click="toAgreement" class="qjc-c-primary">《米因健康大數據體檢平台用戶協議》</span>，未註冊的手機號將自動創建賬號
			</van-checkbox>
		</div>

		<!-- 图形验证码 -->
		<van-popup v-model="imgCode.show" class="img-code">
			<div class="qjc-input-group">
				<span @click="imgCode.show=false" class="close qjc-absolute"></span>
				<h4 class="qjc-fts-32 qjc-c-dark qjc-ftw-n">請輸入圖形驗證碼</h4>
				<van-field v-model="imgCode.code" type="number" maxlength="4" placeholder="請輸入圖形驗證碼"
					class="popup-img-input">
					<van-image slot="button" width="2.184rem" height="0.768rem" :src="imgCode.imgSrc"
						@click="getImgCode()" />
				</van-field>
				<van-button @click="getCode()"
					class="img-code-btn qjc-fonts-32 qjc-bg-primary qjc-c-fff">確定</van-button>
			</div>
		</van-popup>
		<van-popup v-model="picker_show" class="picker_popup" position="bottom" :close-on-click-overlay="false">
			<div class="">
				<van-picker show-toolbar title="地區" :columns="columns" @confirm="onConfirm"
					@cancel="picker_show = false" :default-index="0" />
			</div>
		</van-popup>
	</div>
</template>

<script>
	import Vue from 'vue'
	import {
		NavBar,
		Field,
		Button,
		Toast,
		Popup,
		CountDown,
		Image,
		Picker,
		Checkbox
	} from 'vant'
	Vue.use(NavBar)
		.use(Field)
		.use(Button)
		.use(Toast)
		.use(Popup)
		.use(Picker)
		.use(CountDown)
		.use(Checkbox)
		.use(Image);

	import {
		isPhone,
		isHKPhone,
		isEmail,
		pwdReg
	} from "@/js/utils.js"
	import {
		getVerifyCode,
		accountExist,
		verifyCode,
		getImageCode,
		login
	} from '@/js/axios'

	export default {
		name: 'login',
		data() {
			return {
				account: '', //手機號
				code: '', //验证码
				picker_show: false,
				areaCode: '+852',
				isSend: false, //是否发送验证码 默认为false 用来控制发送按钮内容切换
				imgCode: {
					show: false,
					code: '',
					imgSrc: ''
				}, //图形验证码
				columns: [
					'+852 中國香港',
					'+86 中國大陸'
				],
				areaIndex: 0,
				isAgree: this.$route.params.isAgree ? this.$route.params.isAgree : false,
				phoneCodeText: "獲取驗證碼",
			}
		},
		created() {
			//已经登录跳转至个人中心
			// this.$store.state.isLogin?this.$router.push('/user'):null;
		},
		methods: {
			isImageCode(isSend, a, code) {
				if (code == "+86") {
					return isSend || !isPhone(a)
				} else {
					return isSend || !isHKPhone(a)
				}
			},
			onConfirm(value, index) {
				this.areaIndex = index
				this.areaCode = (index == 0 ? '+852' : '+86')
				this.picker_show = false;
			},
			toAgreement() {
				this.$router.push('/regist/agreement');
			},
			getImgCode() { // 獲取圖形驗證碼
				let account = this.account;
				if(this.isSend){
					return 
				}
				if (!this.isAgree) {
					Toast.fail('請先閲讀並同意用户協議');
					return
				}
				if (!this.accountReg()) {
					return
				}
				

				if (this.accountReg()) {
					this.imgCode.show = true;
					this.imgCode.code = '';
					getImageCode({
						phone: account
					}).then(res => {
						if (res.status == 1) {
							this.imgCode.imgSrc = res.captcha;
						} else {
							Toast.fail(res.msg);
						}
					});
				}


			},
			getCode() { // 获取验证码
				if (this.imgCode.code.trim() == '') {
					Toast.fail('圖形驗證碼不能為空');
					return;
				}
				let account = this.account;
				let data = {
					account: account,
					// type: (isPhone(account) || isHKPhone(account))?1:(isEmail(account)?90:''),//1.手机号注册 2 手机找回密码 90.邮箱注册 91.邮箱找回密码,
					type: 1,
					loa: isHKPhone(account) ? '00852' : '86',
					captcha: this.imgCode.code
				};
				if (this.accountReg()) {
					var that = this;
					getVerifyCode(data).then(function(res2) {
						if (res2.status == 1) {
							that.imgCode.show = false;
							that.isSend = true;
							that.phoneCodeText = '重新發送'
							that.$refs.countDown.start();
						} else if (res2.status == -1) {
							Toast.fail(res2.msg);
							that.imgCode.imgSrc = res2.captcha;
						} else {
							Toast.fail(res2.msg);
						}
					});
				}
			},
			finish() { // 倒计时结束
				this.isSend = false;
				this.phoneCodeText = '重新發送'
				this.$refs.countDown.reset(); // 重置
			},

			accountReg() {
				if (this.account.trim() == '') {
					Toast.fail('請輸入手機號');
					return false;
				} else if (this.areaIndex == 1 && isPhone(this.account)) {
					return true
				} else if (this.areaIndex == 0 && isHKPhone(this.account)) {
					return true
				} else {
					Toast.fail('手機號碼格式不正確，請重新輸入');
					return false;
				}
			}, //賬號驗證

			login() {
				console.log(this.account, this.accountReg());
				if (!this.accountReg()) {
					// Toast.fail('手機號碼格式不正確，請重新輸入');
				} else if (this.code.trim() == '') {
					Toast.fail('驗證碼不能為空');
				} else if (!this.isAgree) {
					Toast.fail('請先閲讀並同意用户協議');
				} else {
					if (isPhone(this.account) || isHKPhone(this.account)) {
						if (this.code.length == 4) {
							let data = {
								mobile: this.account,
								code: this.code,
								loa: isHKPhone(this.account) ? '00852' : '86'
							};
							login(data).then(res => {
								if (res.status == 1) {
									localStorage.setItem('user', JSON.stringify(res.data));
									this.$store.dispatch('login', res.data.access_token).then(() => {
										Toast.success('登錄成功');
										let path = this.$route.query.toUrl ? this.$route.query.toUrl :
											'/user';
										if (path == '/payment/0') { //未登录进入支付会再次跳回选择套餐并检测是否有未完成评估
											path = '/home/buyCombo';
										}
										this.$router.replace(path);

									});
								} else {
									Toast.fail(res.msg);
								}
							})
						} else {
							Toast.fail('驗證碼錯誤');
						}
					} else {
						Toast.fail('您的手機號格式錯誤');
					}
				}
			}
		}
	}
</script>

<style scoped>
	.img-code>>>.van-field__button {
		height: 0.64rem;
	}

	.van-checkbox {
		justify-content: center;
		align-items: flex-start;
		text-align: left;
	}

	.login>>>.van-checkbox__icon {
		margin-top: 0.08rem !important;
	}

	.login>>>.van-checkbox__icon--round .van-icon {
		margin-top: 0.08rem;
	}

	.van-button--disabled .van-button__text {
		color: #848689;
	}

	.van-field {
		font-size: 16px;
		padding: 6px 0;
	}
</style>
<style lang="scss" scoped>
	.get-code {
		border: none;
		border-left: 1px solid #00000020;
		border-radius: 0;
	}


	.login {
		margin-top: 0.88rem;
		color: #A1A7B2;
		padding: 0.68rem 0.4rem;

		.title {
			font-size: 0.44rem;
			margin-bottom: 0.5rem;
		}

		.login-btn {
			height: 0.88rem;
			line-height: 0.88rem;
			margin-top: 0.64rem;
			margin-bottom: 0.32rem;
			border-color: #627AF5;

			span {
				color: #6681FA;
			}
		}

		.forget-pwd {
			color: #A1A7B2;
		}
	}

	footer {
		color: #C6CBD6;
		font-size: 0.2rem;
		bottom: 0.32rem;
	}

	.img-code {
		width: 6rem;
		height: 3.49rem;
		padding: 0.4rem 0.5rem;
		border-radius: 0.12rem;
		margin-top: -0.6rem;
		overflow: visible;

		.close {
			width: 0.44rem;
			height: 0.44rem;
			line-height: 0.44rem;
			top: -0.6rem;
			right: 0;
			font-size: 0.16rem;
			color: #fff;
			background: url(../../assets/images/close.png) no-repeat center center;
			background-size: 0.16rem 0.16rem;
			border-radius: 50%;
			border: 0.02rem solid #fff;
		}

		h4 {
			line-height: 0.46rem;
			margin-bottom: 0.48rem;
		}

		.van-field {
			position: relative;
			width: 100%;
			height: 0.64rem;
			padding: 0;
			border-bottom: 1px solid #DCDEE5;
			z-index: 10;

			.van-image {
				z-index: 9999;
			}
		}

		.van-count-down {
			display: inline;
		}

		.img-code-btn {
			width: 100%;
			height: 0.78rem;
			line-height: 0.78rem;
			margin-top: 0.33rem;
			border-radius: 0.04rem;
			font-weight: 400;
		}
	}

	.mobile_cell {
		width: 100%;
		display: flex;
		align-items: center;
		
		.disabled{
			color: #84868988;
		}
	}

	.pick_cell {
		width: 20%;
		height: 100%;
		line-height: 24px;
		text-align: left;
		color: #333;
		font-weight: 500;
		box-sizing: border-box;
		border-bottom: 1px solid #DCDEE5;
		padding: 6px 0;
		display: flex;
		align-items: center;
	}

	.picker_popup {
		width: 100%;

	}

	.agreement {
		padding: 0 0.2rem;
		text-align: center;
	}

	.img-code .popup-img-input {
		height: 0.768rem;
	}

	.get-code-btn {
		color: #84868988;
	}
</style>