<template>
    <div class="content">

        <van-nav-bar
                title="調查問卷"
                left-arrow
                @click-left="onClickLeft"
                :fixed="true"
        />
        <van-progress
                color="#6681FA"
                :percentage="progressNum"
                :show-pivot="false"
                pivot-text=""
                stroke-width="1"
                track-color="#DCDEE5"
        />
        <van-swipe :loop="false" :show-indicators="true" :touchable="false" ref="nextSwipe"
                   :initial-swipe="currentIndex" :height="swipeHeight">
            <van-swipe-item>
                <div class="swipeBox">
                    <div class="swiperScroll">
                        <div class="question">
                            <div class="avatar"></div>
                            <div class="quesText">
                                您好，請告訴我您的身高、體重和體脂率？
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="reply">
                            <div class="group">
                                <span>身高</span>
                                <div class="groupLabel">
                                    <label>
                                        <van-field v-model="height" placeholder="請填寫" type="number"/>
                                        <div class="unit"><span>cm</span></div>
                                    </label>
                                </div>
                            </div>
                            <div class="group">
                                <span>體重</span>
                                <div class="groupLabel">
                                    <label>
                                        <van-field v-model="weight" placeholder="請填寫" type="number"/>
                                        <div class="unit"><span>kg</span></div>
                                    </label>
                                </div>
                            </div>
                            <div class="group">
                                <span>體脂率</span>
                                <div class="groupLabel">
                                    <label>
                                        <van-field v-model="fat" placeholder="請填寫" type="number"/>
                                        <div class="unit"><span>%</span></div>
                                    </label>
                                </div>
                            </div>
                            <div class="group fitText">
                                若您不知道自身的體脂率信息，米因大數據會為您進行推算。
                                若您屬於健身愛好者，運動員等體脂率較為特殊的人群，請您務必填寫準確的體脂率數值。
                            </div>
                        </div>
                        <div class="nextQues" @click="nextQuest(1)">填好了</div>
                    </div>
                </div>
            </van-swipe-item>
            <van-swipe-item>
                <div class="swipeBox">
                    <div class="swiperScroll">
                        <div class="question">
                            <div class="avatar"></div>
                            <div class="quesText">
                                請告訴我您的三圍信息？
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="reply">
                            <!-- <div class="group">
                                <span>胸圍</span>
                                <div class="groupLabel">
                                    <label>
                                        <van-field v-model="bust" placeholder="請填寫" type="number"/>
                                        <div class="unit"><span>cm</span></div>
                                    </label>
                                </div>
                            </div> -->
                            <div class="group">
                                <span>腰圍</span>
                                <div class="groupLabel">
                                    <label>
                                        <van-field v-model="waist" placeholder="請填寫" type="number"/>
                                        <div class="unit"><span>cm</span></div>
                                    </label>
                                </div>
                            </div>
                            <div class="group">
                                <span>臀圍</span>
                                <div class="groupLabel">
                                    <label>
                                        <van-field v-model="hip" placeholder="請填寫" type="number"/>
                                        <div class="unit"><span>cm</span></div>
                                    </label>
                                </div>
                            </div>
                        </div>
                        <div class="nextQues" @click="nextQuest(2)">填好了</div>
                        <div class="prevQues" @click="prevQuest"></div>
                    </div>
                </div>
            </van-swipe-item>
            <van-swipe-item>
                <div class="swipeBox">
                    <div class="swiperScroll">
                        <div class="question">
                            <div class="avatar"></div>
                            <div class="quesText">
                                請選擇您的居住地？
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="reply">
                            <div class="group">
                                <div class="groupLabel selArea">
                                    <div class="arrow"></div>
                                    <van-field
                                            readonly
                                            clickable
                                            :value="area.text"
                                            placeholder="請選擇"
                                            @click="showPicker3 = true"
                                    />
                                </div>
                            </div>
                        </div>
                        <div class="nextQues" @click="nextQuest(3)">選好了</div>
                        <div class="prevQues" @click="prevQuest"></div>
                    </div>

                </div>
            </van-swipe-item>
            <!-- <van-swipe-item>
                <div class="swipeBox">
                    <div class="swiperScroll">
                        <div class="question">
                            <div class="avatar"></div>
                            <div class="quesText">
                                您的婚姻狀況？
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="reply">

                            <van-radio-group v-model="marriage">
                                <van-radio checked-color="#6883FB" name="2">未婚</van-radio>
                                <van-radio checked-color="#6883FB" name="1">已婚</van-radio>
                                <van-radio checked-color="#6883FB" name="5">分居</van-radio>
                                <van-radio checked-color="#6883FB" name="3">喪偶(男)</van-radio>
                                <van-radio checked-color="#6883FB" name="4">喪偶(女)</van-radio>
                            </van-radio-group>
                        </div>
                        <div class="nextQues" @click="nextQuest(4)">選好了</div>
                        <div class="prevQues" @click="prevQuest"></div>
                    </div>
                </div>
            </van-swipe-item>
            <van-swipe-item>
                <div class="swipeBox">
                    <div class="swiperScroll">
                        <div class="question">
                            <div class="avatar"></div>
                            <div class="quesText">
                                您的子女數目？
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="reply">
                            <van-radio-group v-model="child">
                                <van-radio checked-color="#6883FB" name="0">沒有</van-radio>
                                <van-radio checked-color="#6883FB" name="1">1名</van-radio>
                                <van-radio checked-color="#6883FB" name="2">2名</van-radio>
                                <van-radio checked-color="#6883FB" name="3">3名及以上</van-radio>
                            </van-radio-group>
                        </div>
                        <div class="nextQues" @click="nextQuest(5)">選好了</div>
                        <div class="prevQues" @click="prevQuest"></div>
                    </div>
                </div>
            </van-swipe-item> -->
            <van-swipe-item>
                <div class="swipeBox">
                    <div class="swiperScroll">
                        <div class="question">
                            <div class="avatar"></div>
                            <div class="quesText">
                                您是否喝酒？
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="reply">
                            <van-radio-group v-model="drink">
                                <van-radio checked-color="#6883FB" name="1">不喝</van-radio>
                                <van-radio checked-color="#6883FB" name="4">偶爾喝</van-radio>
                                <van-radio checked-color="#6883FB" name="3">經常喝</van-radio>
                                <van-radio checked-color="#6883FB" name="2">天天喝</van-radio>
                            </van-radio-group>
                        </div>
                        <div class="nextQues" @click="nextQuest(6)">選好了</div>
                        <div class="prevQues" @click="prevQuest"></div>
                    </div>
                </div>
            </van-swipe-item>
            <van-swipe-item>
                <div class="swipeBox">
                    <div class="swiperScroll">
                        <div class="question">
                            <div class="avatar"></div>
                            <div class="quesText">
                                您是否吸煙？
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="reply">
                            <van-radio-group v-model="smoking">
                                <van-radio checked-color="#6883FB" name="1">不吸</van-radio>
                                <van-radio checked-color="#6883FB" name="4">偶爾吸</van-radio>
                                <van-radio checked-color="#6883FB" name="3">經常吸</van-radio>
                                <van-radio checked-color="#6883FB" name="2">天天吸</van-radio>
                            </van-radio-group>
                        </div>
                        <div class="nextQues" @click="nextQuest(7)">選好了</div>
                        <div class="prevQues" @click="prevQuest"></div>
                    </div>
                </div>
            </van-swipe-item>
            <van-swipe-item>
                <div class="swipeBox">
                    <div class="swiperScroll">
                        <div class="question">
                            <div class="avatar"></div>
                            <div class="quesText">
                                您的用餐習慣？
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="reply">
                            <van-radio-group v-model="catering">
                                <van-radio checked-color="#6883FB" name="1">定時定量</van-radio>
                                <van-radio checked-color="#6883FB" name="3">定時但不定量</van-radio>
                                <van-radio checked-color="#6883FB" name="4">不定時但定量</van-radio>
                                <van-radio checked-color="#6883FB" name="2">不定時不定量</van-radio>
                            </van-radio-group>
                        </div>
                        <div class="nextQues" @click="nextQuest(8)">選好了</div>
                        <div class="prevQues" @click="prevQuest"></div>
                    </div>
                </div>
            </van-swipe-item>
            <van-swipe-item>
                <div class="swipeBox">
                    <div class="swiperScroll">
                        <div class="question">
                            <div class="avatar"></div>
                            <div class="quesText">
                                您是否是全日素食者？
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="reply">
                            <van-radio-group v-model="vegetarian">
                                <van-radio checked-color="#6883FB" name="1">不是</van-radio>
                                <van-radio checked-color="#6883FB" name="2">是</van-radio>
                            </van-radio-group>
                        </div>
                        <div class="nextQues" @click="nextQuest(9)">選好了</div>
                        <div class="prevQues" @click="prevQuest"></div>
                    </div>
                </div>
            </van-swipe-item>
            <van-swipe-item>
                <div class="swipeBox">
                    <div class="swiperScroll">
                        <div class="question">
                            <div class="avatar"></div>
                            <div class="quesText">
                                您的運動習慣？
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="reply">
                            <van-radio-group v-model="sports">
                                <van-radio checked-color="#6883FB" name="1">沒有或每周少於1小時</van-radio>
                                <van-radio checked-color="#6883FB" name="2">每周運動1～2小時</van-radio>
                                <van-radio checked-color="#6883FB" name="3">每周運動3～4小時</van-radio>
                                <van-radio checked-color="#6883FB" name="4">每周運動5～6小時</van-radio>
                                <van-radio checked-color="#6883FB" name="5">每週運動7小時以上</van-radio>
                            </van-radio-group>
                        </div>
                        <div class="nextQues" @click="nextQuest(10)">選好了</div>
                        <div class="prevQues" @click="prevQuest"></div>
                    </div>
                </div>
            </van-swipe-item>
            <van-swipe-item>
                <div class="swipeBox">
                    <div class="swiperScroll">
                        <div class="question">
                            <div class="avatar"></div>
                            <div class="quesText">
                                您是否有睡眠障礙？
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="reply">
                            <van-radio-group v-model="sleep">
                                <van-radio checked-color="#6883FB" name="1">沒有</van-radio>
                                <van-radio checked-color="#6883FB" name="4">偶爾</van-radio>
                                <van-radio checked-color="#6883FB" name="3">經常</van-radio>
                                <van-radio checked-color="#6883FB" name="2">非常嚴重</van-radio>
                            </van-radio-group>
                        </div>
                        <div class="nextQues" @click="nextQuest(11)">選好了</div>
                        <div class="prevQues" @click="prevQuest"></div>
                    </div>
                </div>
            </van-swipe-item>
            <van-swipe-item>
                <div class="swipeBox">
                    <div class="swiperScroll">
                        <div class="question">
                            <div class="avatar"></div>
                            <div class="quesText">
                                您患有過下列疾病嗎？（<span style="color: #6681FA;">可多選</span>），全部沒有的話，直接點擊"選好了"即可。
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="reply">
                            <van-checkbox-group v-model="disease" checked-color="#6883FB">
                                <van-checkbox checked-color="#6883FB" shape="square"
                                              v-for="(disItem,index) in diseaseListAll" :name="disItem.KDIS"
                                              :key="index">{{disItem.name}}
                                </van-checkbox>
                            </van-checkbox-group>
                        </div>
                        <div class="nextQues" @click="nextQuest(12)">選好了</div>
                        <div class="prevQues" @click="prevQuest"></div>
                    </div>
                </div>
            </van-swipe-item>
            <van-swipe-item v-if="diseaseShow">
                <div class="swipeBox medicine">
                    <div class="swiperScroll">
                        <div class="question">
                            <div class="avatar"></div>
                            <div class="quesText">
                                請選擇患病的用藥情況 (每種疾病請選擇壹種用藥情況)
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="reply">

                            <table id="selTable" cellspacing="0">
                                <thead>
                                <tr>
                                    <td>疾病名稱</td>
									<td>不服藥</td>
									<td>偶爾服藥</td>
									<td>經常服藥</td>
                                </tr>
                                <tr v-for="(disItemA,indexO) in diseaseArr">
                                    <td>{{disItemA.name}}</td>
                                    <td><label><input type="radio" v-model="disItemA.LEVEL" :name="disItemA.KDIS"
                                                      value="3"><span></span></label></td>
                                    <td><label><input type="radio" v-model="disItemA.LEVEL" :name="disItemA.KDIS"
                                                      value="2"><span></span></label></td>
                                    <td><label><input type="radio" v-model="disItemA.LEVEL" :name="disItemA.KDIS"
                                                      value="1"><span></span></label></td>
                                </tr>
                                </thead>
                                <tbody>
                                </tbody>
                            </table>
                        </div>
                        <div class="nextQues" @click="nextQuest(13)">選好了</div>
                        <div class="prevQues" @click="prevQuest"></div>
                    </div>
                </div>
            </van-swipe-item>
            <van-swipe-item>
                <div class="swipeBox">
                    <div class="swiperScroll">
                        <div class="question">
                            <div class="avatar"></div>
                            <div class="quesText">
                                您是否做過下列手術？（<span style="color: #6681FA;">可多選</span>），全部沒有的話，直接點擊"選好了"即可。
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="reply">
                            <van-checkbox-group v-model="noSurgery">
                                <van-checkbox checked-color="#6883FB" shape="square" name="1">頭或腦部</van-checkbox>
                                <van-checkbox checked-color="#6883FB" shape="square" name="2">眼鼻耳</van-checkbox>
                                <van-checkbox checked-color="#6883FB" shape="square" name="3">口腔、食道</van-checkbox>
                                <van-checkbox checked-color="#6883FB" shape="square" name="4">胸部(心、肺等)</van-checkbox>
                                <van-checkbox checked-color="#6883FB" shape="square" name="5">腹部(胃、肝、膽、胰、腎等)</van-checkbox>
                                <van-checkbox checked-color="#6883FB" shape="square" name="6">關節與骨</van-checkbox>
                                <van-checkbox checked-color="#6883FB" shape="square" name="7">婦科手術</van-checkbox>
                                <van-checkbox checked-color="#6883FB" shape="square" name="8">泌尿系統</van-checkbox>
                            </van-checkbox-group>
                        </div>
                        <div class="nextQues" @click="nextQuest(14)">選好了</div>
                        <div class="prevQues" @click="prevQuest"></div>
                    </div>
                </div>
            </van-swipe-item>
            <van-swipe-item>
                <div class="swipeBox">
                    <div class="swiperScroll">
                        <div class="question">
                            <div class="avatar"></div>
                            <div class="quesText">
                                您的直系親屬有下列疾病嗎？（<span style="color: #6681FA;">可多選</span>），全部沒有的話，直接點擊"選好了"即可。
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="reply">
                            <van-checkbox-group v-model="noRelativesDisease">
                                <van-checkbox checked-color="#6883FB" shape="square" name="1">癌症</van-checkbox>
                                <van-checkbox checked-color="#6883FB" shape="square" name="2">腦中風</van-checkbox>
                                <van-checkbox checked-color="#6883FB" shape="square" name="3">心肌梗塞</van-checkbox>
                                <van-checkbox checked-color="#6883FB" shape="square" name="4">肝硬化</van-checkbox>
                                <van-checkbox checked-color="#6883FB" shape="square" name="5">老年失智</van-checkbox>
                                <van-checkbox checked-color="#6883FB" shape="square" name="6">慢性阻塞性肺炎</van-checkbox>
                                <van-checkbox checked-color="#6883FB" shape="square" name="7">結核病</van-checkbox>
                                <van-checkbox checked-color="#6883FB" shape="square" name="8">先天畸形</van-checkbox>
                            </van-checkbox-group>
                        </div>
                        <div class="nextQues" @click="nextQuest(15)">選好了</div>
                        <div class="prevQues" @click="prevQuest"></div>
                    </div>
                </div>
            </van-swipe-item>
<!--            <van-swipe-item v-if="!isBooking">-->
<!--                <div class="swipeBox">-->
<!--                    <div class="swiperScroll">-->
<!--                        <div class="question">-->
<!--                            <div class="avatar"></div>-->
<!--                            <div class="quesText">-->
<!--                                請填寫您的血液常規檢查结果-->
<!--                            </div>-->
<!--                            <div class="clear"></div>-->
<!--                        </div>-->
<!--                        <div class="reply">-->
<!--                            <div class="group">-->
<!--                                <span>白血球(WBC)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[0].ve" placeholder="請填寫"-->
<!--                                                   @blur="wbc(examineList[0].ve)" type="number"/>-->
<!--                                        <div class="unit" style="line-height: 0.65rem">-->
<!--                                            <span>10<sup>3</sup>(10<sup>9</sup>L)</span></div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>血紅素(HGB)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[1].ve" placeholder="請填寫"-->
<!--                                                   @blur="hgb(examineList[1].ve)" type="number"/>-->
<!--                                        <div class="unit"><span>g/dL</span></div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>血小板(PLT)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[2].ve" placeholder="請填寫"-->
<!--                                                   @blur="plt(examineList[2].ve)" type="number"/>-->
<!--                                        <div class="unit" style="line-height: 0.65rem">-->
<!--                                            <span>10<sup>3</sup>(10<sup>9</sup>L)</span></div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="nextQues" @click="nextQuest(16)">填好了</div>-->
<!--                        <div class="prevQues" @click="prevQuest"></div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </van-swipe-item>-->
<!--            <van-swipe-item v-if="!isBooking">-->
<!--                <div class="swipeBox ">-->
<!--                    <div class="swiperScroll">-->
<!--                        <div class="question">-->
<!--                            <div class="avatar"></div>-->
<!--                            <div class="quesText">-->
<!--                                請填寫您的血糖檢查结果-->
<!--                            </div>-->
<!--                            <div class="clear"></div>-->
<!--                        </div>-->
<!--                        <div class="reply">-->
<!--                            <div class="group">-->
<!--                                <span>空腹血糖(FG)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[3].ve" placeholder="請填寫"-->
<!--                                                   @blur="fg(examineList[3].ve)" type="number"/>-->
<!--                                        <div class="unit">-->
<!--                                            <label style="margin-right: 0.32rem;"> <input v-model="examineList[3].ut"-->
<!--                                                                                          value="B" type="radio"-->
<!--                                                                                          name="fg"-->
<!--                                                                                          @change="fgChange">-->
<!--                                                <p></p> <span>mmol/mol</span>-->
<!--                                            </label>-->
<!--                                            <label> <input v-model="examineList[3].ut" value="A"-->
<!--                                                           type="radio" name="fg"-->
<!--                                                           @change="fgChange">-->
<!--                                                <p></p> <span>mg/dL</span>-->
<!--                                            </label>-->
<!--                                        </div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>糖化血色素(HbA1c)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[4].ve" placeholder="請填寫"-->
<!--                                                   @blur="hb1ac(examineList[4].ve)" type="number"/>-->
<!--                                        <div class="unit"><span>%</span></div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="nextQues" @click="nextQuest(17)">填好了</div>-->
<!--                        <div class="prevQues" @click="prevQuest"></div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </van-swipe-item>-->
<!--            <van-swipe-item v-if="!isBooking">-->
<!--                <div class="swipeBox">-->
<!--                    <div class="swiperScroll">-->
<!--                        <div class="question">-->
<!--                            <div class="avatar"></div>-->
<!--                            <div class="quesText">-->
<!--                                請填寫您的血脂肪檢查结果-->
<!--                            </div>-->
<!--                            <div class="clear"></div>-->
<!--                        </div>-->
<!--                        <div class="reply">-->
<!--                            <div class="group">-->
<!--                                <span>總膽固醇(CHOL)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[5].ve" placeholder="請填寫"-->
<!--                                                   @blur="chol(examineList[5].ve)" type="number"/>-->
<!--                                        <div class="unit">-->
<!--                                            <label style="margin-right: 0.32rem;"> <input v-model="examineList[5].ut"-->
<!--                                                                                          value="B" type="radio"-->
<!--                                                                                          name="chol"-->
<!--                                                                                          @change="cholChange">-->
<!--                                                <p></p><span>mmol/mol</span></label>-->
<!--                                            <label> <input v-model="examineList[5].ut" value="A"-->
<!--                                                           type="radio" name="chol"-->
<!--                                                           @change="cholChange">-->
<!--                                                <p></p><span>mg/dL</span></label>-->
<!--                                        </div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>低密度脂蛋白膽固醇(LDLC)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[6].ve" placeholder="請填寫"-->
<!--                                                   @blur="ldlc(examineList[6].ve)" type="number"/>-->
<!--                                        <div class="unit">-->
<!--                                            <label style="margin-right: 0.32rem;"> <input v-model="examineList[6].ut"-->
<!--                                                                                          value="B" type="radio"-->
<!--                                                                                          name="ldlc"-->
<!--                                                                                          @change="ldlcChange">-->
<!--                                                <p></p><span>mmol/mol</span></label>-->
<!--                                            <label> <input v-model="examineList[6].ut" value="A"-->
<!--                                                           type="radio" name="ldlc"-->
<!--                                                           @change="ldlcChange">-->
<!--                                                <p></p><span>mg/dL</span></label>-->
<!--                                        </div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>高密度脂蛋白膽固醇(HDLC)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[7].ve" placeholder="請填寫"-->
<!--                                                   @blur="hdlc(examineList[7].ve)" type="number"/>-->
<!--                                        <div class="unit">-->
<!--                                            <label style="margin-right: 0.32rem;"> <input v-model="examineList[7].ut"-->
<!--                                                                                          value="B" type="radio"-->
<!--                                                                                          name="hdlc"-->
<!--                                                                                          @change="hdlcChange">-->
<!--                                                <p></p><span>mmol/mol</span></label>-->
<!--                                            <label> <input v-model="examineList[7].ut" value="A"-->
<!--                                                           type="radio" name="hdlc"-->
<!--                                                           @change="hdlcChange">-->
<!--                                                <p></p><span>mg/dL</span></label>-->
<!--                                        </div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>三酸甘油脂(TG)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[8].ve" placeholder="請填寫"-->
<!--                                                   @blur="tg(examineList[8].ve)" type="number"/>-->
<!--                                        <div class="unit">-->
<!--                                            <label style="margin-right: 0.32rem;"> <input v-model="examineList[8].ut"-->
<!--                                                                                          value="B" type="radio"-->
<!--                                                                                          name="tg"-->
<!--                                                                                          @change="tgChange">-->
<!--                                                <p></p><span>mmol/mol</span></label>-->
<!--                                            <label> <input v-model="examineList[8].ut" value="A"-->
<!--                                                           type="radio" name="tg"-->
<!--                                                           @change="tgChange">-->
<!--                                                <p></p><span>mg/dL</span></label>-->
<!--                                        </div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="nextQues" @click="nextQuest(18)">填好了-->
<!--                        </div>-->
<!--                        <div class="prevQues" @click="prevQuest"></div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </van-swipe-item>-->
<!--            <van-swipe-item v-if="!isBooking">-->
<!--                <div class="swipeBox">-->
<!--                    <div class="swiperScroll">-->
<!--                        <div class="question">-->
<!--                            <div class="avatar"></div>-->
<!--                            <div class="quesText">-->
<!--                                請填寫您的肝膽功能檢查结果-->
<!--                            </div>-->
<!--                            <div class="clear"></div>-->
<!--                        </div>-->
<!--                        <div class="reply">-->
<!--                            <div class="group">-->
<!--                                <label>-->

<!--                                </label>-->
<!--                                <span>肝指數GOT</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[9].ve" placeholder="請填寫"-->
<!--                                                   @blur="got(examineList[9].ve)" type="number"/>-->
<!--                                        <div class="unit"><span>IU/L(U/L)</span></div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>肝指數GPT</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[10].ve" placeholder="請填寫"-->
<!--                                                   @blur="gpt(examineList[10].ve)" type="number"/>-->
<!--                                        <div class="unit"><span>IU/L(U/L)</span></div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>堿性磷酸酶(ALP)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[11].ve" placeholder="請填寫"-->
<!--                                                   @blur="alp(examineList[11].ve)" type="number"/>-->
<!--                                        <div class="unit"><span>IU/L(U/L)</span></div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>白蛋白(ALB)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[12].ve" placeholder="請填寫"-->
<!--                                                   @blur="alb(examineList[12].ve)" type="number"/>-->
<!--                                        <div class="unit">-->
<!--                                            <label style="margin-right: 0.32rem;"> <input v-model="examineList[12].ut"-->
<!--                                                                                          value="B" type="radio"-->
<!--                                                                                          name="alb"-->
<!--                                                                                          @change="albChange">-->
<!--                                                <p></p><span>mmol/mol</span></label>-->
<!--                                            <label> <input v-model="examineList[12].ut"-->
<!--                                                           value="A"-->
<!--                                                           type="radio" name="alb"-->
<!--                                                           @change="albChange">-->
<!--                                                <p></p><span>mg/dL</span></label>-->
<!--                                        </div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="nextQues" @click="nextQuest(19)">填好了</div>-->
<!--                        <div class="prevQues" @click="prevQuest"></div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </van-swipe-item>-->
<!--            <van-swipe-item v-if="!isBooking">-->
<!--                <div class="swipeBox">-->
<!--                    <div class="swiperScroll">-->
<!--                        <div class="question">-->
<!--                            <div class="avatar"></div>-->
<!--                            <div class="quesText">-->
<!--                                請填寫您的腎功能檢查结果-->
<!--                            </div>-->
<!--                            <div class="clear"></div>-->
<!--                        </div>-->
<!--                        <div class="reply">-->
<!--                            <div class="group">-->
<!--                                <span>肌酸酐(Cre)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[13].ve" placeholder="請填寫"-->
<!--                                                   @blur="cre(examineList[13].ve)" type="number"/>-->
<!--                                        <div class="unit">-->
<!--                                            <label style="margin-right: 0.32rem;"> <input v-model="examineList[13].ut"-->
<!--                                                                                          value="A"-->
<!--                                                                                          type="radio" name="cre"-->
<!--                                                                                          @change="creChange">-->
<!--                                                <p></p><span>mg/dL</span></label>-->
<!--                                            <label> <input v-model="examineList[13].ut"-->
<!--                                                           value="B" type="radio" name="cre"-->
<!--                                                           @change="creChange">-->
<!--                                                <p></p><span>μmol/L</span></label>-->
<!--                                        </div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>尿酸(UA)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[14].ve" placeholder="請填寫"-->
<!--                                                   @blur="ua(examineList[14].ve)" type="number"/>-->
<!--                                        <div class="unit">-->
<!--                                            <label style="margin-right: 0.32rem;"> <input v-model="examineList[14].ut"-->
<!--                                                                                          value="A"-->
<!--                                                                                          type="radio" name="ua"-->
<!--                                                                                          @change="uaChange">-->
<!--                                                <p></p><span>mg/dL</span></label>-->
<!--                                            <label> <input v-model="examineList[14].ut"-->
<!--                                                           value="B" type="radio" name="ua"-->
<!--                                                           @change="uaChange">-->
<!--                                                <p></p><span>μmol/L</span></label>-->
<!--                                        </div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                            <div class="group">-->
<!--                                <span>腎絲球過濾率(eGFR)</span>-->
<!--                                <div class="groupLabel">-->
<!--                                    <label>-->
<!--                                        <van-field v-model="examineList[15].ve" placeholder="請填寫"-->
<!--                                                   @blur="egfr(examineList[15].ve)" type="number"/>-->
<!--                                        <div class="unit"><span-->
<!--                                                style="line-height: 0.65rem">ml/min/1.73m <sup>2</sup></span></div>-->
<!--                                    </label>-->
<!--                                </div>-->
<!--                            </div>-->
<!--                        </div>-->
<!--                        <div class="nextQues" @click="nextQuest(20)">提交問卷</div>-->
<!--                        <div class="prevQues" @click="prevQuest">-->
<!--                        </div>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </van-swipe-item>-->
<!--            <van-swipe-item v-if="isBooking">-->
            <van-swipe-item>
                <div class="swipeBox">
                    <div class="swiperScroll">
                        <div class="question">
                            <div class="avatar"></div>
                            <div class="quesText">
                                您好，為了獲得您的準確的體型信息和生化指標信息，請您預約您的線下體驗：
                            </div>
                            <div class="clear"></div>
                        </div>
                        <div class="reply">
                            <!-- <p style="font-weight: 500;margin-top: 0.56rem;text-align: left;">您最希望：</p> -->
                            <div class="group" style="margin-top: 0.32rem">
								<div class="group-cell">
									<span class="group-cell-label">體檢地點</span>
									<div class="group-cell-input">
										<div class="groupLabel selArea">
											<div class="arrow"></div>
											<van-field
													readonly
													clickable
													:value="bookingArea.text"
													placeholder="請選擇"
													@click="showPicker = true"
											/>
										</div>
										<div class="group-add" @click="considerAreaShow = true" v-show="!considerAreaShow" >
											<div class="group-add-icon">+</div>
											可以考慮的體檢地點
										</div>
										<div class="group-add-cell" v-show="considerAreaShow">
											<div class="groupLabel selArea">
											    <div class="arrow"></div>
											    <van-field
											            readonly
											            clickable
											            :value="bookingArea2.text"
											            placeholder="可以考慮的體檢地點"
											            @click="showPicker2 = true"
											    />
											</div>
											<div class="cancel-consider" @click="() => {considerAreaShow = false, bookingArea2 = ''}">
												<div class="cancel-consider-box">-</div>
											</div>
										</div>
									</div>
								</div>
                            </div>
                            <div class="group">
								<div class="group-cell">
									<span class="group-cell-label">體檢時間</span>
									<div class="group-cell-input">
										<div class="groupLabel">
											<div class="arrow"></div>
											<input type="text" style="width: 100%;" readonly="readonly"
												   @click="pickerShow = true" v-model="bookTime" placeholder="请填写">
										</div>
										<div class="group-add" @click="considerTimeShow = true" v-show="!considerTimeShow" >
											<div class="group-add-icon">+</div>
											可以考慮的體檢時間
										</div>
										<div class="group-add-cell" v-show="considerTimeShow">
											<div class="groupLabel selArea">
											    <div class="arrow"></div>
											    <van-field
											            readonly
											            clickable
											            :value="bookTime2"
											            placeholder="可以考慮的體檢時間"
											            @click="pickerShow2 = true"
											    />
											</div>
											<div class="cancel-consider" @click="() => {considerTimeShow = false, bookTime2 = ''}">
												<div class="cancel-consider-box">-</div>
											</div>
										</div>
									</div>	
								</div>
                            </div>
                            <!-- <p style="font-weight: 500;margin-top: 0.56rem;text-align: left;">可以考慮 <span
                                    style="color: #909399;">（可選項）</span></p>
                            <div class="group" style="margin-top: 0.32rem">
                                <span>進行體檢的地點：</span>
                                <div class="groupLabel selArea">
                                    <div class="arrow"></div>
                                    <van-field
                                            readonly
                                            clickable
                                            :value="bookingArea2.text"
                                            placeholder="請選擇"
                                            @click="showPicker2 = true"
                                    />
                                </div>
                            </div>
                            <div class="group">
                                <span>進行體檢的時間：</span>
                                <div class="groupLabel">
                                    <div class="arrow"></div>
                                    <input type="text" style="width: 100%;" readonly="readonly"
                                           @click="pickerShow2 = true" v-model="bookTime2" placeholder="請填寫">
                                </div>
                            </div> -->
                            <p style="font-weight: 500;margin-top: 0.56rem"></p>
                            <div class="group">
								<div class="group-cell">
									<span class="group-cell-label">聯系電話</span>
								
									<div class="groupLabel">
										<van-field v-model="phone" maxlength="11" placeholder="請填寫"
												   type="number"/>
									</div>
								</div>
								<div class="group-cell margin-top15">
									<span class="group-cell-label code-text ">验证码</span>
									<div class="group-cell-input">
										<div class="groupLabel">
											<label>
												<van-field v-model="phoneCode" maxlength="4" placeholder="请填写" type="number"/>
												<span @click="sendCode"
													  :class="{ disabled: sending }"
													  class="sendCodeText"><van-loading v-if="sending" type="spinner" /><span v-else class="ac-default">短信驗證碼</span></span>
											</label>
										</div>
										<div class="group-desc fitText">
											香港體檢會在3個工作日內向您致電，向您確認並完成預約。
										</div>
									</div>
								</div>
                            </div>
                           
                        </div>
						<div class="group group-desc-text fitText">
						    1.體檢完成後，我們會自動獲取您的相關體檢結果信息，並完成您的健康大數據體檢報告，而無需您再次輸入。<br>
						    2.屆時您將會收到短訊通知，並可回到本平台登錄查看您的健康大數據體檢報告。
						</div>
                        <div class="nextQues" style="width: 4.3rem;" :class="{ disabled: submiting }" @click="nextQuest(21)"><van-loading v-if="submiting" type="spinner" /><span v-else>我知道了，進行預約</span></div>
                        <div class="prevQues" @click="prevQuest"></div>
                    </div>
                </div>
            </van-swipe-item>
        </van-swipe>
        <div class="mark" v-show="markShowNull">
            <div>
                <div class="markBox">
                    <img src="../../assets/images/errorTip.png" alt="">
                    <div class="tipText">
                        您尚有未完成的健康大數據評估，是否繼續完成？
                    </div>
                    <div class="btnGroup">
                        <div class="btnEsc" @click="backHome">返回首頁</div>
                        <div class="btnEnt" @click="close">繼續完成</div>
                    </div>
                    <div style="clear: both;"></div>
                </div>
                <div class="close" @click="close"></div>
            </div>
        </div>
        <div class="mark" v-show="markShowError">
            <div>
                <div class="markBoxError">
                    <div class="markBoxErrorImg">
                        <img src="../../assets/images/tips.png" alt="">
                    </div>
                    <div class="tipText2 tipText3" v-html="errorTip">

                    </div>
                    <div class="btnGroup">
                        <div class="btnEnt2 qjc-fts-32" @click="close">我知道了</div>
                    </div>
                </div>
                <div class="close" @click="close"></div>
            </div>
        </div>

        <van-popup v-model="pickerShow" position="bottom">
            <div class="dateName">
                <ul>
                    <li>年</li>
                    <li>月</li>
                    <li>日</li>
                    <li>時</li>
                    <li>分</li>
                </ul>
            </div>
            <van-datetime-picker
                    v-model="currentTime"
                    type="datetime"
                    :filter="filter"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :min-hour="9"
                    :max-hour="12"
                    confirm-button-text="確定"
                    cancel-button-text="取消"
                    @cancel="pickerShow = false"
                    @confirm="confirm"
            />
        </van-popup>
        <van-popup v-model="pickerShow2" position="bottom">
            <div class="dateName">
                <ul>
                    <li>年</li>
                    <li>月</li>
                    <li>日</li>
                    <li>時</li>
                    <li>分</li>
                </ul>
            </div>
            <van-datetime-picker
                    v-model="currentTime2"
                    type="datetime"
                    :filter="filter"
                    :min-date="minDate"
                    :max-date="maxDate"
                    :min-hour="9"
                    :max-hour="12"
                    confirm-button-text="確定"
                    cancel-button-text="取消"
                    @cancel="pickerShow2 = false"
                    @confirm="confirm2"
            />
        </van-popup>
        <van-popup v-model="showPicker" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="columns"
                    @cancel="showPicker = false"
                    confirm-button-text="確定"
                    cancel-button-text="取消"
                    @confirm="onConfirm"
            />
        </van-popup>
        <van-popup v-model="showPicker2" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="columns"
                    @cancel="showPicker2 = false"
                    confirm-button-text="確定"
                    cancel-button-text="取消"
                    @confirm="onConfirm2"
            />
        </van-popup>
        <van-popup v-model="showPicker3" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="areaList"
                    @cancel="showPicker3 = false"
                    confirm-button-text="確定"
                    cancel-button-text="取消"
                    @confirm="onConfirm3"
            />
        </van-popup>
    </div>
</template>

<script>
    import question from '../../js/question';
    import Vue from 'vue';
    import {
        NavBar,
        Progress,
        Swipe,
        SwipeItem,
        RadioGroup,
        Radio,
        Checkbox,
        CheckboxGroup,
        Field,
        Picker,
        Popup,
        Area,
        DatetimePicker,
        Cell,
        CellGroup,
		Loading
    } from 'vant';
    import Login from "../login/index";

    Vue.use(NavBar).use(Progress).use(Swipe).use(SwipeItem).use(RadioGroup).use(Radio).use(Checkbox).use(CheckboxGroup).use(Field).use(Picker).use(Popup).use(Area).use(DatetimePicker).use(Cell).use(CellGroup).use(Loading);
    export default {
        ...question
    }
</script>

<style src="../../assets/css/quesstion.css" scoped>

</style>
<style scoped>


    .content >>> .van-icon-arrow-left:before {
        color: #40444D;
    }

    .content >>> .van-nav-bar__title {
        font-size: 0.36rem;
        font-family: PingFang SC;
        font-weight: 500;
        color: rgba(51, 51, 51, 1);
    }

    .content >>> .van-progress {
        margin-top: 46px;
    }

    .content >>> .van-radio {
        padding: 0.4rem 0;
        border-bottom: 0.01rem solid #EEEEEE;
    }

    .content >>> .van-radio:last-child {
        border: none;
    }

    .content >>> .van-checkbox {
        padding: 0.4rem 0;
        border-bottom: 0.01rem solid #EEEEEE;
    }

    .content >>> .van-checkbox__icon .van-icon {
        border-radius: 4px;
    }

    .content >>> .van-swipe__indicators {
        bottom: -12px;
    }

    .content >>> .van-cell {
        padding: 0;
        float: left;
        width: 100%;
        height: 0.7rem;
    }

    .content >>> .van-cell input {
        float: left;
        font-size: 0.32rem;
        height: 0.7rem;
        line-height: 0.7rem;
    }

    .content >>> .van-cell input::placeholder {
        font-size: 0.32rem;
        font-family: PingFang SC;
        font-weight: 300;
        color: rgba(161, 167, 178, 1);
    }

    .selArea >>> .van-cell {
        width: 100% !important;
    }
</style>
