import {number} from "echarts/src/export";
import {questionCommit, getVerifyCode, bookingCommit, isPay, addressList} from "./axios";
import {isPhone, isHKPhone} from "./utils";

import {Toast} from 'vant'  //vant-UI的提示组件

export default {
    name: "question1",
    data() {
        return {
            progressNum: 0,
            questionNum: 20,
            currentIndex: 0,
            markShowNull: false,
            markShowError: false,
            radio: '',
            result: ['a', 'b'],
            swipeHeight: 0,
            errorTip: '',

            areaList: [
                {text: "中國香港", code: '44000'},
                {text: "中國臺灣", code: '0000'},
                {text: "江蘇", code: '3200'},
                {text: "上海", code: '3100'},
                {text: "北京", code: '1100'},
                {text: "天津", code: '1200'},
                {text: "浙江", code: '3300'},
                {text: "廣東", code: '4400'},
                {text: "遼寧", code: '2100'},
                {text: "山東", code: '3700'},
                {text: "內蒙古", code: '1500'},
                {text: "重慶", code: '5000'},
                {text: "福建", code: '3500'},
                {text: "海南", code: '4600'},
                {text: "湖北", code: '4200'},
                {text: "湖南", code: '4300'},
                {text: "陝西", code: '6100'},
                {text: "河南", code: '4100'},
                {text: "黑龍江", code: '2300'},
                {text: "青海", code: '6300'},
                {text: "雲南", code: '5300'},
                {text: "安徽", code: '3400'},
                {text: "四川", code: '5100'},
                {text: "貴州", code: '5200'},
                {text: "甘肅", code: '6200'},
                {text: "江西", code: '3600'},
                {text: "吉林", code: '2200'},
                {text: "山西", code: '1400'},
                {text: "河北", code: '1300'},
                {text: "寧夏", code: '6400'},
                {text: "廣西", code: '4500'},
                {text: "新疆", code: '6500'},
                {text: "西藏", code: '5400'},
            ],

            height: '',
            weight: '',
            fat: '',
            bust: '',
            waist: '',
            hip: '',
            area: '',
            marriage: '',
            child: '',
            drink: '',
            smoking: '',
            catering: '',
            vegetarian: '',
            sports: '',
            sleep: '',
            diseaseList: [
                {"KDIS": "K01", "LEVEL": "0"},
                {"KDIS": "K02", "LEVEL": "0"},
                {"KDIS": "K03", "LEVEL": "0"},
                {"KDIS": "K04", "LEVEL": "0"},
                {"KDIS": "K05", "LEVEL": "0"},
                {"KDIS": "K06", "LEVEL": "0"},
                {"KDIS": "K07", "LEVEL": "0"},
                {"KDIS": "K08", "LEVEL": "0"},
                {"KDIS": "K09", "LEVEL": "0"},
                {"KDIS": "K10", "LEVEL": "0"},
                {"KDIS": "K11", "LEVEL": "0"},
                {"KDIS": "K12", "LEVEL": "0"},
                {"KDIS": "K13", "LEVEL": "0"}
            ],
            diseaseListAll: [
                {"KDIS": "K01", "LEVEL": "0", "name": "高血壓"},
                {"KDIS": "K02", "LEVEL": "0", "name": "高血脂"},
                {"KDIS": "K03", "LEVEL": "0", "name": "糖尿病"},
                {"KDIS": "K04", "LEVEL": "0", "name": "慢性肝病"},
                {"KDIS": "K05", "LEVEL": "0", "name": "慢性腎臟病"},
                {"KDIS": "K06", "LEVEL": "0", "name": "癌症"},
                {"KDIS": "K07", "LEVEL": "0", "name": "肝硬化"},
                {"KDIS": "K08", "LEVEL": "0", "name": "慢性阻塞性肺炎"},
                {"KDIS": "K09", "LEVEL": "0", "name": "關節炎"},
                {"KDIS": "K10", "LEVEL": "0", "name": "心臟病"},
                {"KDIS": "K11", "LEVEL": "0", "name": "腦中風"},
                {"KDIS": "K12", "LEVEL": "0", "name": "胃腸潰瘍"},
                {"KDIS": "K13", "LEVEL": "0", "name": "睡眠障礙"}
            ],
            disease: [],
            diseaseArr: [],
            diseaseShow: false,
            noSurgery: [],
            noRelativesDisease: [],
            examineList: [
                {"ne": "WBC", "ve": "", "ut": "A"},
                {"ne": "HGB", "ve": "", "ut": "A"},
                {"ne": "PLT", "ve": "", "ut": "A"},
                {"ne": "FG", "ve": "", "ut": "B"},
                {"ne": "HBA1C", "ve": "", "ut": "A"},
                {"ne": "CHOL", "ve": "", "ut": "B"},
                {"ne": "LDLC", "ve": "", "ut": "B"},
                {"ne": "HDLC", "ve": "", "ut": "B"},
                {"ne": "TG", "ve": "", "ut": "B"},
                {"ne": "GOT", "ve": "", "ut": "A"},
                {"ne": "GPT", "ve": "", "ut": "A"},
                {"ne": "ALP", "ve": "", "ut": "A"},
                {"ne": "ALB", "ve": "", "ut": "B"},
                {"ne": "CRE", "ve": "", "ut": "B"},
                {"ne": "UA", "ve": "", "ut": "B"},
                {"ne": "EGFR", "ve": "", "ut": "A"}
            ],
            // isBooking: JSON.parse(sessionStorage.getItem("isBooking")) || false,
            isBooking: true,
            bookTime: '',
            bookTime2: '',
            pickerShow: false,
            pickerShow2: false,
            bookingArea: '',
            bookingArea2: '',
            showPicker: false,
            showPicker2: false,
            showPicker3: false,
            columns: [
                ],
            currentTime: '',
            currentTime2: '',
            minDate:'',
            maxDate:'',
            phone: '',
            phoneCode: '',
            package: JSON.parse(sessionStorage.getItem('package'))|| 1,
			isYY: 0,//1：跳过预约，直接调用接口。0：填写预约信息 默认为0
			
			sending: false,// 正在发送短信
			submiting: false,// 正在提交
			considerAreaShow: false,
			considerTimeShow: false,
        }
    },
    created() {
        this.currentIndex= 14;
        document.title = '調查問卷';
        addressList().then( res => {
            if(res.status == '1'){
                this.columns = [];
                res.data.forEach(item => {
                    let address = {};
                    address.id = item.id;
                    address.text = item.address;
                    this.columns.push(address);
                });
            }
        });

        let minDate=new Date(new Date().setHours(0, 0, 0, 0));
        minDate.setDate(minDate.getDate()+4);
//如果是前三天就-3
        minDate.getDate();//得到后三天的时间
        this.minDate = minDate;
        this.currentTime = minDate;
        this.currentTime2 = minDate;
        let maxDate=new Date(new Date().setHours(12, 30, 0, 0));
        maxDate.setDate(maxDate.getDate()+14);
//如果是前三天就-3
        maxDate.getDate();//得到后三天的时间
        this.maxDate = maxDate;
		isPay().then(res => {
			if(res.status != 1){
				Toast.fail('您還未購買套餐，請先購買');
				this.$router.replace('/home/buyCombo');
			}else{
				this.isYY = res.data.appoint;
				// res.data.bioche == '1'?this.isBooking = true:this.isBooking = false;
				this.isBooking = true;

				if (this.isBooking) {
				    this.questionNum = 15;
				} else {
				    this.questionNum = 19;
				}
			}
		})
    },
    mounted() {
        this.jumpQuest();
        this.swipeHeight = document.documentElement.clientHeight - 47;
    },
    methods: {
        onConfirm(value) {
            this.bookingArea = value;
            this.showPicker = false;
        },
        onConfirm2(value) {
            this.bookingArea2 = value;
            this.showPicker2 = false;
        },
        onConfirm3(value) {
            this.area = value;
            this.showPicker3 = false;
        },
        close() {
            this.markShowNull = false;
            this.markShowError = false;
        },
        onClickLeft() {
            this.markShowNull = true;
        },
        backHome() {
            this.$router.push({path: '/'})
        },
        onChange(index) {
            Toast('当前 Swipe 索引：' + index);
        },
        jumpQuest() {
            this.$refs.nextSwipe.swipeTo(this.currentIndex, {
                immediate: true
            });
            this.progressNum = ((this.currentIndex + 1) / this.questionNum) * 100;
			
			//切换效果
			this.$nextTick(() => {
				var target = document.querySelectorAll('.van-swipe-item')[this.currentIndex];
				var prevToggle = document.querySelector('.toggle-question');
				prevToggle?prevToggle.classList.remove('toggle-question'):'';
				target.className += ' toggle-question';
			});
        },
        isGo() {
            this.currentIndex++;
            this.jumpQuest()
        },
        sendCode() {
            if (this.phone == '') {
                Toast.fail('請輸入手機號碼！');
            }else if (!(isPhone(this.phone) || isHKPhone(this.phone))) {
                    Toast.fail("手機號碼格式有誤")
            }else {
                let data = {
                    account: this.phone,
                    type: 4,
                    loa: isHKPhone(this.phone) ? '00852' : '86'
                };
				this.sending = true;
                getVerifyCode(data).then((res) => {
					this.sending = false;
                    if (res.status == 1) {
                        Toast.success(res.msg);
                    }else{
						Toast.fail(res.msg);
					}
                })
            }
        },
        // wbc(num) {
        //     if (num > 150 || num < 0 && num != '') {
        //         this.tipsMark()
        //     }
        // },
        // hgb(num) {
        //     if (num > 25 || num < 1 && num != '') {
        //         this.tipsMark()
        //     }
        // },
        // plt(num) {
        //     if (num > 1800 || num < 1 && num != '') {
        //         this.tipsMark()
        //     }
        // },
        // fgChange() {
        //     if (this.examineList[3].ut == "A") {
        //         if (this.examineList[3].ve > 700 || this.examineList[3].ve < 30 && this.examineList[3].ve != '') {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[3].ut == "B") {
        //         if (this.examineList[3].ve > 40 || this.examineList[3].ve < 1.5 && this.examineList[3].ve != '') {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // fg(num) {
        //     if (this.examineList[3].ut == "A") {
        //         if (num > 700 || num < 30 && num != '') {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[3].ut == "B") {
        //         if (num > 40 || num < 1.5 && num != '') {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // hb1ac(num) {
        //     if (num > 25 || num < 1 && num != '') {
        //         this.tipsMark()
        //     }
        // },
        // cholChange() {
        //     if (this.examineList[5].ut == "A") {
        //         if (this.examineList[5].ve > 1100 || this.examineList[5].ve < 50) {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[5].ut == "B") {
        //         if (this.examineList[5].ve > 28.5 || this.examineList[5].ve < 1.2) {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // chol(num) {
        //     if (this.examineList[5].ut == "A") {
        //         if (num > 1100 || num < 50 && num != '') {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[5].ut == "B") {
        //         if (num > 28.5 || num < 1.2 && num != '') {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // ldlcChange() {
        //     if (this.examineList[6].ut == "A") {
        //         if (this.examineList[6].ve > 650 || this.examineList[6].ve < 1) {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[6].ut == "B") {
        //         if (this.examineList[6].ve > 17 || this.examineList[6].ve < 0) {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // ldlc(num) {
        //     if (this.examineList[6].ut == "A") {
        //         if (num > 650 || num < 1 && num != '') {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[6].ut == "B") {
        //         if (num > 17 || num < 0 && num != '') {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // hdlcChange() {
        //     if (this.examineList[7].ut == "A") {
        //         if (this.examineList[7].ve > 250 || this.examineList[7].ve < 1) {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[7].ut == "B") {
        //         if (this.examineList[7].ve > 7 || this.examineList[7].ve < 0) {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // hdlc(num) {
        //     if (this.examineList[7].ut == "A") {
        //         if (num > 250 || num < 1 && num != '') {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[7].ut == "B") {
        //         if (num > 7 || num < 0 && num != '') {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // tgChange() {
        //     if (this.examineList[8].ut == "A") {
        //         if (this.examineList[8].ve > 9000 || this.examineList[8].ve < 1) {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[8].ut == "B") {
        //         if (this.examineList[8].ve > 110 || this.examineList[8].ve < 0) {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // tg(num) {
        //     if (this.examineList[8].ut == "A") {
        //         if (num > 9000 || num < 1 && num != '') {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[8].ut == "B") {
        //         if (num > 110 || num < 0 && num != '') {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // got(num) {
        //     if (num > 2000 || num < 1 && num != '') {
        //         this.tipsMark()
        //     }
        // },
        // gpt(num) {
        //     if (num > 4000 || num < 1 && num != '') {
        //         this.tipsMark()
        //     }
        // },
        // alp(num) {
        //     if (num > 6500 || num < 1 && num != '') {
        //         this.tipsMark()
        //     }
        // },
        // albChange() {
        //     if (this.examineList[12].ut == "A") {
        //         if (this.examineList[12].ve > 7 || this.examineList[12].ve < 1) {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[12].ut == "B") {
        //         if (this.examineList[12].ve > 70 || this.examineList[12].ve < 10) {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // alb(num) {
        //     if (this.examineList[12].ut == "A") {
        //         if (num > 7 || num < 1 && num != '') {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[12].ut == "B") {
        //         if (num > 70 || num < 10 && num != '') {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // creChange() {
        //     if (this.examineList[13].ut == "A") {
        //         if (this.examineList[13].ve > 40 || this.examineList[13].ve < 0) {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[13].ut == "B") {
        //         if (this.examineList[13].ve > 3600 || this.examineList[13].ve < 0) {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // cre(num) {
        //     if (this.examineList[13].ut == "A") {
        //         if (num > 40 || num < 0 && num != '') {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[13].ut == "B") {
        //         if (num > 3600 || num < 0 && num != '') {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // uaChange() {
        //     if (this.examineList[14].ut == "A") {
        //         if (this.examineList[14].ve > 20 || this.examineList[14].ve < 0) {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[14].ut == "B") {
        //         if (this.examineList[14].ve > 1200 || this.examineList[14].ve < 0) {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // ua(num) {
        //     if (this.examineList[14].ut == "A") {
        //         if (num > 20 || num < 0 && num != '') {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[14].ut == "B") {
        //         if (num > 1200 || num < 0 && num != '') {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // egfr(num) {
        //     if (num > 300 || num < 1 && num != '') {
        //         this.tipsMark()
        //     }
        // },

        nextQuest(isGo) {

            let _that = this;
            // this.isGo();
            if (isGo === 1) {
                if (this.height == '' || this.height < 130 || this.height > 200) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適身高，建議範圍：130~200'
                } else if (this.weight == '' || this.weight < 30 || this.weight > 160) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適體重，建議範圍：30~160'
                } else if (this.fat != '' && this.fat < 3 || this.fat > 75) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適體脂率，建議範圍：3~75';
                } else {
                    this.isGo()
                }


            } else if (isGo === 2) {

                if (this.bust == '' || this.bust < 40 || this.bust > 150) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適胸圍，建議範圍：40~150'
                } else if (this.waist == '' || this.waist < 40 || this.waist > 150) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適腰圍，建議範圍：40~150'
                } else if (this.hip == '' || this.hip < 60 || this.hip > 165) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適臀圍，建議範圍：60~165'
                } else {
                    this.isGo()
                }

            } else if (isGo === 3) {

                if (this.area == '' || this.area == '' || typeof area == "number") {
                    this.markShowError = true;
                    this.errorTip = '請選擇您的居住地'
                } else {
                    this.isGo()
                }

            } else if (isGo === 4) {
               
				this.isGo()
            } else if (isGo === 5) {
                
				this.isGo()
            } else if (isGo === 6) {
                if (this.drink == '') {
                    this.markShowError = true;
                    this.errorTip = '请选择您是否喝酒'
                } else {
                    this.isGo()
                }
            } else if (isGo === 7) {
                if (this.smoking == '') {
                    this.markShowError = true;
                    this.errorTip = '请选择您是否吸煙'
                } else {
                    this.isGo()
                }

            } else if (isGo === 8) {
                if (this.catering == '') {
                    this.markShowError = true;
                    this.errorTip = '请选择您的用餐習慣'
                } else {
                    this.isGo()
                }
            } else if (isGo === 9) {
                if (this.vegetarian == '') {
                    this.markShowError = true;
                    this.errorTip = '请选择您是否是全日素食者'
                } else {
                    this.isGo()
                }

            } else if (isGo === 10) {
                if (this.sports == '') {
                    this.markShowError = true;
                    this.errorTip = '请选择您的運動習慣'
                } else {
                    this.isGo()
                }

            } else if (isGo === 11) {
                if (this.sleep == '') {
                    this.markShowError = true;
                    this.errorTip = '请选择您是否有睡眠障礙'
                } else {
                    this.isGo()
                }

            } else if (isGo === 12) {
                if (this.disease.length > 0) {
                    this.diseaseShow = true
                } else {
                    this.diseaseShow = false
                }

                this.isGo()
            } else if (isGo === 13) {
                let _that = this;
                try {
                    this.diseaseArr.forEach(function (item, index) {
                        if (item.LEVEL == '0') {
                            _that.markShowError = true;
                            _that.errorTip = '請填寫' + item.name + '信息!';
                            throw new Error("EndIterative")
                        } else {
                            _that.diseaseList.forEach(function (itemL, number) {
                                if (item.KDIS == itemL.KDIS) {
                                    _that.diseaseList[number].LEVEL = item.LEVEL
                                }
                            });

                        }
                    });
                    _that.isGo()
                } catch (e) {
                    console.log('END')
                }

            } else if (isGo === 14) {
				this.isGo()
            } else if (isGo === 15) {
				if(this.isYY == 1){
					this.nextQuest(21);
				}else{
					this.isGo()
				}
            }
            // else if (isGo === 16) {
            //     if (this.examineList[0].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[1].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[2].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else {
            //         this.isGo()
            //     }
            //
            //
            // } else if (isGo === 17) {
            //     if (this.examineList[3].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[4].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else {
            //         this.isGo()
            //     }
            //
            // } else if (isGo === 18) {
            //     if (this.examineList[5].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[6].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[7].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[8].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else {
            //         this.isGo()
            //     }
            //
            // } else if (isGo === 19) {
            //     if (this.examineList[9].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[10].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[11].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[12].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else {
            //         this.isGo()
            //     }
            // } else if (isGo === 20) {
            //     if (this.examineList[13].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[14].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else {
            //         let data = {
            //             access_token: '$2y$13$wjGN6O3/MxtuR.L525b8c.38gRX6sgs.2e4JDUvh7Q8mJHBSEitK.',
            //             area: this.area.code,
            //             bust: this.bust,
            //             childcnt: this.child,
            //             disease: this.diseaseList,
            //             drink: this.drink,
            //             examine: this.examineList,
            //             famdis: this.noRelativesDisease,
            //             fat: this.fat,
            //             habeat: this.catering,
            //             habsport: this.sports,
            //             height: this.height,
            //             hip: this.hip,
            //             marry: this.marriage,
            //             oper: this.noSurgery,
            //             sleep: this.sleep,
            //             smoke: this.smoking,
            //             vege: this.vegetarian,
            //             waist: this.waist,
            //             weight: this.weight,
            //         };
            //         questionCommit('', data).then((res) => {
            //             if(res.status == '1'){
            //                 Toast.success("提交問卷成功！");
            //                 this.$router.push({path:'/result/'+res.id});
            //             }else {
            //                 Toast.fail(res.msg);
            //             }
            //
            //         })
            //     }
            // }
            else if (isGo === 21) {
                if(this.isYY == 0){
					if (this.bookingArea == '') {
					    Toast.fail("請選擇體檢地點！")
					} else if (this.bookTime == '') {
					    Toast.fail("請選擇體檢日期與時間")
					} else if (this.phone == '') {
					    Toast.fail("請填寫手機號碼")
					} else if (!(isPhone(this.phone) || isHKPhone(this.phone))) {
					    Toast.fail("手機號碼格式有誤")
					} else if (this.phoneCode == '') {
					    Toast.fail("驗證碼不能為空！")
					} else {
						this.submiting = true;
					    this.submitData();
					
					}
				}else if(this.isYY == 1){
					this.submiting = true;
					this.submitData();
				}
            }
        },
		submitData() {
			let data = {
			    access_token: '$2y$13$wjGN6O3/MxtuR.L525b8c.38gRX6sgs.2e4JDUvh7Q8mJHBSEitK.',
			    area: this.area.code,
			    bust: this.bust,
			    childcnt: this.child,
			    disease: this.diseaseList,
			    drink: this.drink,
			    famdis: this.noRelativesDisease,
			    fat: this.fat,
			    habeat: this.catering,
			    habsport: this.sports,
			    height: this.height,
			    hip: this.hip,
			    marry: this.marriage,
			    oper: this.noSurgery,
			    sleep: this.sleep,
			    smoke: this.smoking,
			    vege: this.vegetarian,
			    waist: this.waist,
			    weight: this.weight,
			    package: this.package
			};
			if(this.isYY == 0){
				let data2 = {
					address: this.bookingArea.id,
					time: (new Date(this.currentTime)).getTime() / 1000,
					address2: this.bookingArea2.id,
					time2: (new Date(this.currentTime2)).getTime() / 1000,
					phone: this.phone,
					code: this.phoneCode
				};
				Object.assign(data,data2);
			}
			bookingCommit('', data).then((res) => {
				this.submiting = false;
			    if(res.status == '1'){
			        Toast.success(res.msg);
			        this.$router.replace('/user/history')
			    }else{
					Toast.fail(res.msg);
				}
			})
		},
        prevQuest() {
            this.currentIndex--;
            this.jumpQuest()
        },
        tipsMark() {
            this.markShowError = true;
            this.errorTip = "<p>您填寫的結果可能存在較大偏差，請您仔細核對該數值和所用單位是否正確並與您的報告一致。</p><br/><p>若您已經核對確認，並堅持以該結果進行提交和評估，我們可能會根據大數據算法對該結果進行一定程度上的修正和處理，以提供更合理的大數據評估結果和建議。</p>"
        },
        confirm() {
            if(new Date(this.currentTime).getDay() == '0'){
                Toast.fail("請選擇週日以外的日期!");
            }else {
                this.pickerShow = false;
                let dateFormat = (new Date(this.currentTime)).getTime();
                this.bookTime = new Date(dateFormat).toLocaleString().replace(/:\d{1,2}$/, ' ');
            }
        },
        confirm2() {
            if(new Date(this.currentTime2).getDay() == '0'){
                Toast.fail("請選擇週日以外的日期!");
            }else {
                this.pickerShow2 = false;
                let dateFormat = (new Date(this.currentTime2)).getTime();
                this.bookTime2 = new Date(dateFormat).toLocaleString().replace(/:\d{1,2}$/, ' ');
            }
        },
        filter(type, options) {
            if (type === 'minute') {
                return options.filter(option => option % 15 === 0)
            }
            if (type === 'hour') {
                return options.filter(option => option < 13 && option > 8)
            }
            return options;
        },
    },
    watch: {
        disease(newVal, oldVal) {
            if (newVal.length > 0) {
                let _that = this;
                _that.diseaseArr = [];
                this.disease.forEach(function (item, index) {
                    _that.diseaseListAll.forEach(function (itemL, number) {
                        if (item == itemL.KDIS) {
                            _that.diseaseArr.push(_that.diseaseListAll[number]);
                        }
                    })
                });
                if (this.isBooking) {
                    this.questionNum = 16;
                } else {
                    this.questionNum = 20;
                }
            } else {
                if (this.isBooking) {
                    this.questionNum = 15;
                } else {
                    this.questionNum = 19;
                }
            }
        }
    }
}
