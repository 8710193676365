import {number} from "echarts/src/export";
import {questionCommit, editQuestionCommit} from "./axios";
import {isPhone, isHKPhone} from "./utils";

import {Toast} from 'vant'  //vant-UI的提示组件

export default {
    name: "question1",
    data() {
        return {
            progressNum: 0,
            questionNum: 7,
            currentIndex: 0,
            markShowNull: false,
            markShowError: false,
            radio: '',
            result: ['a', 'b'],
            swipeHeight: 0,
            errorTip: '',
            editID: JSON.parse(sessionStorage.getItem("editQuestionID")) || '',


            height: '',
            weight: '',
            fat: '',
            bust: '',
            waist: '',
            hip: '',
            // examineList: [
            //     {"ne": "WBC", "ve": "", "ut": "A"},
            //     {"ne": "HGB", "ve": "", "ut": "A"},
            //     {"ne": "PLT", "ve": "", "ut": "A"},
            //     {"ne": "FG", "ve": "", "ut": "B"},
            //     {"ne": "HBA1C", "ve": "", "ut": "A"},
            //     {"ne": "CHOL", "ve": "", "ut": "B"},
            //     {"ne": "LDLC", "ve": "", "ut": "B"},
            //     {"ne": "HDLC", "ve": "", "ut": "B"},
            //     {"ne": "TG", "ve": "", "ut": "B"},
            //     {"ne": "GOT", "ve": "", "ut": "A"},
            //     {"ne": "GPT", "ve": "", "ut": "A"},
            //     {"ne": "ALP", "ve": "", "ut": "A"},
            //     {"ne": "ALB", "ve": "", "ut": "B"},
            //     {"ne": "CRE", "ve": "", "ut": "B"},
            //     {"ne": "UA", "ve": "", "ut": "B"},
            //     {"ne": "EGFR", "ve": "", "ut": "A"}
            // ],
        }
    },
    created() {
        document.title = '修改信息';
        this.currentIndex = 0
    },
    mounted() {
        let data = {
            access_token: localStorage.getItem('access_token') || '$2y$13$wjGN6O3/MxtuR.L525b8c.38gRX6sgs.2e4JDUvh7Q8mJHBSEitK.'
        };
        editQuestionCommit(this.editID, data).then((res) => {
            let oldData = JSON.parse(window.atob(res.data));
            console.log(oldData);
            if (res.status == '1') {
                this.height = oldData.input.height;
                this.weight = oldData.input.weight;
                this.fat = oldData.input.fat;
                this.bust = oldData.input.bust;
                this.waist = oldData.input.waist;
                this.hip = oldData.input.hip;
                // this.examineList = oldData.examine
            }
        });

        this.jumpQuest();
        this.swipeHeight = document.documentElement.clientHeight - 47;
    },
    methods: {
        close() {
            this.markShowNull = false;
            this.markShowError = false;
        },
        onClickLeft() {
            this.markShowNull = true;
        },
        backHome() {
            this.$router.push({path: '/'})
        },
        onChange(index) {
            Toast('当前 Swipe 索引：' + index);
        },
        jumpQuest() {
            this.$refs.nextSwipe.swipeTo(this.currentIndex, {
                immediate: false
            });
            this.progressNum = ((this.currentIndex + 1) / this.questionNum) * 100;
        },
        isGo() {
            this.currentIndex++;
            this.jumpQuest()
        },
        // wbc(num) {
        //     if (num > 150 || num < 0 && num != '') {
        //         this.tipsMark()
        //     }
        // },
        // hgb(num) {
        //     if (num > 25 || num < 1 && num != '') {
        //         this.tipsMark()
        //     }
        // },
        // plt(num) {
        //     if (num > 1800 || num < 1 && num != '') {
        //         this.tipsMark()
        //     }
        // },
        // fgChange() {
        //     if (this.examineList[3].ut == "A") {
        //         if (this.examineList[3].ve > 700 || this.examineList[3].ve < 30 && this.examineList[3].ve != '') {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[3].ut == "B") {
        //         if (this.examineList[3].ve > 40 || this.examineList[3].ve < 1.5 && this.examineList[3].ve != '') {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // fg(num) {
        //     if (this.examineList[3].ut == "A") {
        //         if (num > 700 || num < 30 && num != '') {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[3].ut == "B") {
        //         if (num > 40 || num < 1.5 && num != '') {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // hb1ac(num) {
        //     if (num > 25 || num < 1 && num != '') {
        //         this.tipsMark()
        //     }
        // },
        // cholChange() {
        //     if (this.examineList[5].ut == "A") {
        //         if (this.examineList[5].ve > 1100 || this.examineList[5].ve < 50) {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[5].ut == "B") {
        //         if (this.examineList[5].ve > 28.5 || this.examineList[5].ve < 1.2) {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // chol(num) {
        //     if (this.examineList[5].ut == "A") {
        //         if (num > 1100 || num < 50 && num != '') {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[5].ut == "B") {
        //         if (num > 28.5 || num < 1.2 && num != '') {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // ldlcChange() {
        //     if (this.examineList[6].ut == "A") {
        //         if (this.examineList[6].ve > 650 || this.examineList[6].ve < 1) {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[6].ut == "B") {
        //         if (this.examineList[6].ve > 17 || this.examineList[6].ve < 0) {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // ldlc(num) {
        //     if (this.examineList[6].ut == "A") {
        //         if (num > 650 || num < 1 && num != '') {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[6].ut == "B") {
        //         if (num > 17 || num < 0 && num != '') {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // hdlcChange() {
        //     if (this.examineList[7].ut == "A") {
        //         if (this.examineList[7].ve > 250 || this.examineList[7].ve < 1) {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[7].ut == "B") {
        //         if (this.examineList[7].ve > 7 || this.examineList[7].ve < 0) {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // hdlc(num) {
        //     if (this.examineList[7].ut == "A") {
        //         if (num > 250 || num < 1 && num != '') {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[7].ut == "B") {
        //         if (num > 7 || num < 0 && num != '') {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // tgChange() {
        //     if (this.examineList[8].ut == "A") {
        //         if (this.examineList[8].ve > 9000 || this.examineList[8].ve < 1) {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[8].ut == "B") {
        //         if (this.examineList[8].ve > 110 || this.examineList[8].ve < 0) {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // tg(num) {
        //     if (this.examineList[8].ut == "A") {
        //         if (num > 9000 || num < 1 && num != '') {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[8].ut == "B") {
        //         if (num > 110 || num < 0 && num != '') {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // got(num) {
        //     if (num > 2000 || num < 1 && num != '') {
        //         this.tipsMark()
        //     }
        // },
        // gpt(num) {
        //     if (num > 4000 || num < 1 && num != '') {
        //         this.tipsMark()
        //     }
        // },
        // alp(num) {
        //     if (num > 6500 || num < 1 && num != '') {
        //         this.tipsMark()
        //     }
        // },
        // albChange() {
        //     if (this.examineList[12].ut == "A") {
        //         if (this.examineList[12].ve > 7 || this.examineList[12].ve < 1) {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[12].ut == "B") {
        //         if (this.examineList[12].ve > 70 || this.examineList[12].ve < 10) {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // alb(num) {
        //     if (this.examineList[12].ut == "A") {
        //         if (num > 7 || num < 1 && num != '') {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[12].ut == "B") {
        //         if (num > 70 || num < 10 && num != '') {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // creChange() {
        //     if (this.examineList[13].ut == "A") {
        //         if (this.examineList[13].ve > 40 || this.examineList[13].ve < 0) {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[13].ut == "B") {
        //         if (this.examineList[13].ve > 3600 || this.examineList[13].ve < 0) {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // cre(num) {
        //     if (this.examineList[13].ut == "A") {
        //         if (num > 40 || num < 0 && num != '') {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[13].ut == "B") {
        //         if (num > 3600 || num < 0 && num != '') {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // uaChange() {
        //     if (this.examineList[14].ut == "A") {
        //         if (this.examineList[14].ve > 20 || this.examineList[14].ve < 0) {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[14].ut == "B") {
        //         if (this.examineList[14].ve > 1200 || this.examineList[14].ve < 0) {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // ua(num) {
        //     if (this.examineList[14].ut == "A") {
        //         if (num > 20 || num < 0 && num != '') {
        //             this.tipsMark()
        //         }
        //     } else if (this.examineList[14].ut == "B") {
        //         if (num > 1200 || num < 0 && num != '') {
        //             this.tipsMark()
        //         }
        //     }
        // },
        // egfr(num) {
        //     if (num > 300 || num < 1 && num != '') {
        //         this.tipsMark()
        //     }
        // },

        nextQuest(isGo) {

            let _that = this;
            // this.isGo();
            if (isGo === 1) {
                if (this.height == '' || this.height < 130 || this.height > 200) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適身高，建議範圍：130~200'
                } else if (this.weight == '' || this.weight < 30 || this.weight > 160) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適體重，建議範圍：30~160'
                } else if (this.fat != '' && this.fat < 3 || this.fat > 75) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適體脂率，建議範圍：3~75';
                } else {
                    this.isGo()
                }


            } else if (isGo === 2) {

                if (this.bust == '' || this.bust < 40 || this.bust > 150) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適胸圍，建議範圍：40~150'
                } else if (this.waist == '' || this.waist < 40 || this.waist > 150) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適腰圍，建議範圍：40~150'
                } else if (this.hip == '' || this.hip < 60 || this.hip > 165) {
                    this.markShowError = true;
                    this.errorTip = '請輸入合適臀圍，建議範圍：60~165'
                } else {
                    // this.isGo()
                    let data = {
                        bust: this.bust,
                        // examine: this.examineList,
                        fat: this.fat,
                        height: this.height,
                        hip: this.hip,
                        waist: this.waist,
                        weight: this.weight,
                        old_id: this.editID
                    };
                    questionCommit('', data).then((res) => {
                        if (res.status == '1') {
                            Toast.success(res.msg);
                            this.$router.push({path: '/result/' + res.id});
                        } else {
							Toast.fail(res.msg)
						}
                    })
                }

            }
            // else if (isGo === 3) {
            //     if (this.examineList[0].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[1].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[2].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else {
            //         this.isGo()
            //     }
            //
            //
            // } else if (isGo === 4) {
            //     if (this.examineList[3].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[4].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else {
            //         this.isGo()
            //     }
            //
            // } else if (isGo === 5) {
            //     if (this.examineList[5].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[6].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[7].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[8].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else {
            //         this.isGo()
            //     }
            //
            // } else if (isGo === 6) {
            //     if (this.examineList[9].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[10].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[11].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[12].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else {
            //         this.isGo()
            //     }
            // } else if (isGo === 7) {
            //     if (this.examineList[13].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else if (this.examineList[14].ve == '') {
            //         this.markShowError = true;
            //         this.errorTip = '請填寫信息!'
            //     } else {
            //         let data = {
            //             bust: this.bust,
            //             examine: this.examineList,
            //             fat: this.fat,
            //             height: this.height,
            //             hip: this.hip,
            //             waist: this.waist,
            //             weight: this.weight,
            //             old_id:this.editID
            //         };
            //         questionCommit('', data).then((res) => {
            //             if(res.status == '1'){
            //                 Toast.success('修改成功！');
            //                 this.$router.push({path:'/result/'+res.id});
            //             }
            //         })
            //     }
            // }
        },
        prevQuest() {
            this.currentIndex--;
            this.jumpQuest()
        },
        tipsMark() {
            this.markShowError = true;
            this.errorTip = "<p>您填寫的結果可能存在較大偏差，請您仔細核對該數值和所用單位是否正確並與您的報告一致。</p><br/><p>若您已經核對確認，並堅持以該結果進行提交和評估，我們可能會根據大數據算法對該結果進行一定程度上的修正和處理，以提供更合理的大數據評估結果和建議。</p>";
        },
    },
    watch: {}
}
